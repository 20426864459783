import React, {useMemo} from "react";
import dynamic from "next/dynamic";
import 'react-quill/dist/quill.snow.css';

const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['link'],
    ['clean']
  ],
}

const quillFormats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'link'
]
type QuillRichTextProps = {
  onChange: any
  defaultValue:string
};

export function QuillRichTextEditor({onChange, defaultValue}:QuillRichTextProps) {
  const ReactQuill = useMemo(() => dynamic(() => import('react-quill'), {ssr: false}), []);
  return (
    <>
      <ReactQuill
        className="mt-1"
        value={defaultValue}
        theme="snow"
        bounds={`[data-text-editor="bound"]`}
        onChange={onChange}
        modules={quillModules}
        formats={quillFormats}
      />
    </>
  )
}
