
export default function Participate() {
  return <>
    <div className="flex-column justify-center">
  <div>
    <div className="relative mx-auto w-64">
      <div className="flex relative z-10 justify-between items-center min-h-24 gap-2 p-2 px-1">
        <div className="flex-1 flex justify-start">
          <div className="grid gap-1">
            <div
              className="w-2.5 h-2.5 rounded-full bg-accepted   "
              title="Chris Wolf"
            />
            <div
              className="w-2.5 h-2.5 rounded-full bg-maybe   "
              title="Marcel Schiller"
            />
          </div>
        </div>
        <div className="flex-1 flex justify-center">
          <div className="grid gap-1 grid-cols-2">
            <div
              className="w-2.5 h-2.5 rounded-full bg-accepted   "
              title="Tim Danhof"
            />
            <div
              className="w-2.5 h-2.5 rounded-full bg-accepted   "
              title="Jan Washausen"
            />
            <div
              className="w-2.5 h-2.5 rounded-full bg-accepted   "
              title="Vincent Ketzer"
            />
            <div
              className="w-2.5 h-2.5 rounded-full bg-accepted   "
              title="Chris Wolf"
            />
            <div
              className="w-2.5 h-2.5 rounded-full bg-maybe   "
              title="Marcel Schiller"
            />
          </div>
        </div>
        <div className="flex-1 flex justify-center">
          <div className="grid gap-1 grid-cols-2">
            <div
              className="w-2.5 h-2.5 rounded-full bg-accepted   "
              title="Tim Danhof"
            />
            <div
              className="w-2.5 h-2.5 rounded-full bg-accepted   "
              title="Jan Washausen"
            />
            <div
              className="w-2.5 h-2.5 rounded-full bg-accepted   "
              title="Vincent Ketzer"
            />
            <div
              className="w-2.5 h-2.5 rounded-full bg-accepted   "
              title="Chris Wolf"
            />
            <div
              className="w-2.5 h-2.5 rounded-full bg-declined   "
              title="Edwin Schwarz"
            />
            <div
              className="w-2.5 h-2.5 rounded-full bg-declined   "
              title="Marcel Schiller"
            />
          </div>
        </div>
        <div className="flex-1 flex justify-center">
          <div className="grid gap-1 grid-cols-2">
            <div
              className="w-2.5 h-2.5 rounded-full bg-accepted   "
              title="Gregor von Westphalen"
            />
            <div
              className="w-2.5 h-2.5 rounded-full bg-accepted   "
              title="Alexander Nollenberger"
            />
            <div
              className="w-2.5 h-2.5 rounded-full bg-accepted   "
              title="Markus Ziereis"
            />
            <div
              className="w-2.5 h-2.5 rounded-full bg-maybe   "
              title="Alexander Ochs"
            />
          </div>
        </div>
        <div className="flex-1" />
      </div>
      <div className="absolute inset-0 bg-gray-150 flex justify-between items-center rounded-lg">
        <div className="w-5 border-r-2 border-t-2 border-b-2 border-gray-50 h-12" />
        <div className="w-0.5 bg-gray-50 h-full flex items-center justify-center">
          <div className="w-12 h-12 rounded-full border-2 border-gray-50 flex-[0_0_3rem]" />
        </div>
        <div className="w-5 border-l-2 border-t-2 border-b-2 border-gray-50 h-12" />
      </div>
    </div>
    <div className="flex-1 flex justify-center flex-wrap gap-1 mt-2">
      <div
        className="w-2.5 h-2.5 rounded-full bg-accepted   "
        title="Tim Latteier"
      />
      <div
        className="w-2.5 h-2.5 rounded-full bg-accepted   "
        title="Josue-Emanuelle M`Bila"
      />
      <div
        className="w-2.5 h-2.5 rounded-full bg-accepted   "
        title="Josue-Emanuelle M`Bila"
      />
      <div
        className="w-2.5 h-2.5 rounded-full bg-maybe   "
        title="Josue-Emanuelle M`Bila"
      />
      <div
        className="w-2.5 h-2.5 rounded-full bg-maybe   "
        title="Josue-Emanuelle M`Bila"
      />
      <div
        className="w-2.5 h-2.5 rounded-full bg-declined   "
        title="Josue-Emanuelle M`Bila"
      />
    </div>
  </div>
  <div className="flex justify-center font-semibold gap-3 mt-3">
    <div className="text-accepted">16 Zusagen</div>
    <div className="text-maybe">5 Unsicher</div>
    <div className="text-declined">3 Absagen</div>
  </div>
</div>

  </>
}
