import { Match } from "@/sources/kicker-amateur/types";

export const isFutureMatch = (match: Match) => {
  if (!match.Kickoff) {
    return false;
  }

  const kickoffDate = new Date(match.Kickoff);
  const now = new Date();
  return now < kickoffDate;
};

export const getKickoffDetails = (match: Match) => {
  if (!match.Kickoff) {
    return { kickoffDateString: "", kickoffTime: "" };
  }

  const kickoffDate = new Date(match.Kickoff);
  return {
    kickoffDateString: kickoffDate.toDateString(),
    kickoffTime: `${kickoffDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${kickoffDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`,
  };
};

export const formatDate = (dateStr: string) => {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  return date.toLocaleDateString("de-DE", options);
};

export const formatDateStyleShort = (dateStr: string) => {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = { dateStyle: "short" };
  return date.toLocaleDateString("de-DE", options);
};
export const formatDateStyleFull = (dateStr: string) => {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = { dateStyle: "full" };
  return date.toLocaleDateString("de-DE", options);
};

export const formatDateForForm = (date: Date | string | undefined): string => {
  if (!date) {
    return "";
  }

  if (typeof date === "string") {
    return formatDateToISOWithoutTimezone(new Date(date));
  }

  return formatDateToISOWithoutTimezone(date);
};

export function formatDateToISOWithoutTimezone(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export function formatDateToNumber(date: Date): number {
  return date.getTime() / 1000;
}

