import CheckList, { CheckListItem } from "@/app/pwa/components/CheckList";
import { OnboardingTag, OnboardingSlide, OnboardingScreenshot } from "../OnboardingSlider";

import BildSeeleS from "@/assets/onboarding/bild-seele_s.webp";
import BildSeeleM from "@/assets/onboarding/bild-seele_m.webp";
import BildSeeleL from "@/assets/onboarding/bild-seele_l.webp";
import News from "../Features/News";

export default function SlideSoul() {
  return <>
    <OnboardingSlide
      imageContent={
        <>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img className="w-full h-full object-cover object-left-top"
            src={BildSeeleS.src}
            alt=""
            sizes="(min-width: 30em) 30rem ,100vw"
            srcSet={`${BildSeeleS.src} 400w, ${BildSeeleM.src} 500w, ${BildSeeleL.src} 600w,`} />
          <OnboardingScreenshot className="flex justify-end" screenshotClassName="origin-bottom-left rotate-[3deg] scale-75 w-[360px] h-[560px] max-h-[110%] -right-20">
            <News />
          </OnboardingScreenshot>
        </>
      }
      textContent={
        <div>
          <OnboardingTag>für den Platzwart</OnboardingTag>
          <h2 className="text-center text-balance text-xl font-bold mt-2 max-w-[25rem] mx-auto">Der digitale Helfer für die wichtigsten Menschen am Sportplatz</h2>
          <CheckList className="mt-2 max-w-[14rem] mx-auto">
            <CheckListItem>Trainingszeiten der Teams</CheckListItem>
            <CheckListItem>Kalender mit Spielübersicht</CheckListItem>
            <CheckListItem>Teaminterne News mit Pushes</CheckListItem>
          </CheckList>
        </div>
      }
    />
  </>
}
