export function integerToRoman(num: number): string {
  const romanMap: { [key: number]: string } = {
    1000: "M",
    900: "CM",
    500: "D",
    400: "CD",
    100: "C",
    90: "XC",
    50: "L",
    40: "XL",
    10: "X",
    9: "IX",
    5: "V",
    4: "IV",
    1: "I",
  };

  let roman = "";

  for (let key in romanMap) {
    const value = romanMap[key];
    while (num >= parseInt(key)) {
      roman += value;
      num -= parseInt(key);
    }
  }

  return roman;
}
