import { getDateWithDay, getTime } from "@/helpers/dateHelper";

export default function Training() {
  const now = new Date(); // Aktuelle Zeit
  const tomorrow = new Date(now); // Kopie der aktuellen Zeit
  // Einen Tag hinzufügen
  tomorrow.setDate(now.getDate() + 1);
  // Uhrzeit auf 13:30 setzen
  tomorrow.setHours(13, 30, 0, 0);
  return <div>
    {/* TODO:Timo Date */}
    <a className="flex flex-col justify-center items-start px-1 duration-fast hover:bg-gray-100/50 active:bg-gray-100" href=""><h3 className="text-lg font-semibold ">Konditraining</h3><div className="flex items-center justify-center gap-2">
      <span className="">{getDateWithDay(Math.floor(tomorrow.getTime() / 1000))}</span>
              <span className="w-0.5 bg-gray-150 h-4"></span>
              <span className="">{getTime(tomorrow.getTime())} Uhr</span>
    </div>
            </a>
  <div className="flex justify-between items-baseline px-3 border-t border-gray-150 pt-2.5 mt-2.5">
    <span className="font-bold">Deine Teilnahme:</span>
    <span className="text-gray-200 text-sm">1 Unsicher</span>
  </div>
  <div className="flex flex-col gap-2 mt-1.5">
    <div className="">
      <div className="">
        <div className="flex gap-x-0.5">
          <button
            className="
  
  button
  button--accepted
  
  w-1/3 flex justify-center hover:z-10 rounded-r-none  relative"
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="mr-1.5 flex-shrink-0"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 5 10.29 19 4 12.83"
              />
            </svg>
            Zusagen
          </button>
          <button
            className="
  
  button
  button--maybe
  
  w-1/3 flex justify-center hover:z-10 rounded-none  relative"
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="mr-1.5 flex-shrink-0"
            >
              <path
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 7.006c1-2.757 3.901-4.413 6.869-3.92 2.968.495 5.135 2.995 5.131 5.919C18 13.002 11.825 15 11.825 15m.165 5h.02"
              />
            </svg>
            Unsicher
          </button>
          <button
            className="
  
  button
  button--declined
  
  w-1/3 flex justify-center hover:z-10 rounded-l-none  relative"
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="mr-1.5 flex-shrink-0"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth={2}
                d="M5 19 19 5m0 14L5 5"
              />
            </svg>
            Absagen
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

}
