"use client"
import CopyButton from "../../../welcome_app/components/CopyButton";
import { Club } from "@/sources/kicker-amateur/types";
import { ClubImage } from "@/app/pwa/components/TeamImage";

export default function SlideUpdate({pwa, homeUrl} : {pwa:Club, homeUrl: string}) {
  return <>
    <div className="bg-gradient-to-b from-primary-forced to-primary-variant-forced flex flex-col items-center justify-center h-full text-white-forced pb-[7.5rem]">
      <div className="bg-white-forced rounded-lg p-1.5">
        <ClubImage club={pwa} size="16" />
      </div>
      <div className="mt-10">
        <h3 className="text-xl font-bold text-center">Jetzt kostenlos loslegen: Das kicker-Vereinsheim für deinen Verein</h3>
        <div className="text-lg mt-6 grid grid-cols-[max-content_max-content] justify-center items-baseline gap-x-1.5 gap-y-2.5">
          <span className="bg-gray-50 rounded-full text-gray-900 font-bold w-5 h-5 flex justify-center items-center">1</span>
          <div>Link zum Vereinsheim kopieren</div>
          <span className="bg-gray-50 rounded-full text-gray-900 font-bold w-5 h-5 flex justify-center items-center">2</span>
          <div>Link in deinem Browser öffnen</div>
          <span className="bg-gray-50 rounded-full text-gray-900 font-bold w-5 h-5 flex justify-center items-center">3</span>
          <div>App zu Homescreen hinzufügen</div>
        </div>
      </div>

      <div className="mt-7 text-center text-lg">
        <CopyButton url={homeUrl} />
      </div>
      
      <div className="mt-6 text-center opacity-75">
        <div className="font-semibold">
          Link manuell kopieren:
        </div>
        {homeUrl}
      </div>
    </div>
  </>
}
