export default function TrainingForm() {

  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);

  return <>
    <div
  className="overflow-y-hidden h-full w-full bg-gray-100"
  id="container"
>
  {/*$*/}
  {/*/$*/}
  <header
    className="bg-gray-50 pl-max-5-or-safe-area-inset-left pr-max-5-or-safe-area-inset-right pt-[calc(0.75rem_+_env(safe-area-inset-top))] pb-3 shadow-header 
  sticky top-0 z-50
  max-w-basic-page "
  >
    <div className="flex justify-between items-center">
      <div className="flex-1">
        <button type="button" className="mobile-nav-button  -ml-4">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth={2}
              d="M7.5 12 16 3.5m0 17L7.5 12"
            />
          </svg>
        </button>
      </div>
      <h2 className="uppercase font-compressed font-bold text-xl text-center">
        Neues Training
      </h2>
      <div className="flex-1" />
    </div>
  </header>
  <div className="px-2 mt-4 max-w-basic-page pb-24">
    <form>
      <div className="flex flex-col gap-y-3">
        <div className="!hidden bg-surface text-gray-900 rounded-md @container/card z-[12] flex flex-col gap-y-3 p-4">
          <fieldset className="fieldset flex flex-col ">
            <input
              className="block w-full px-3 py-2.5 border border-gray-900 bg-transparent rounded focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary placeholder:text-gray-200 peer form-field w-full mt-1 "
              id="title_input"
              type="text"
              name="title"
              readOnly
            />
            <label
              htmlFor="title_input"
              className="font-bold font-compressed peer-focus:text-secondary order-first block "
            >
              Titel
            </label>
          </fieldset>
          <div className="h-0 -mb-3 relative">
            <fieldset className="fieldset float-right">
              <label className="font-bold font-compressed peer-focus:text-secondary flex w-full gap-x-2 items-center !font-normal">
                <input
                  className="input-checkbox w-5 h-5 shrink-0 appearance-none 
   bg-transparent border border-gray-900 rounded
   focus:border-secondary focus:outline-none focus:ring-1 focus:ring-secondary
   checked:border-secondary checked:bg-secondary
  mt-0.5 "
                  id="isClubEvent"
                  type="checkbox"
                  name="isClubEvent"
                  readOnly
                />
                Ganzer Verein
              </label>
            </fieldset>
          </div>
          <div className="flex gap-x-3 flex-col">
            <fieldset className="fieldset flex flex-col flex-1 !opacity-100">
              <div
                className="peer r-select css-b62m3t-container"
                id="teamIds_input"
              >
                <span
                  id="react-select-teamIds_input-live-region"
                  className="css-7pg0cj-a11yText"
                />
                <span
                  aria-live="polite"
                  aria-atomic="false"
                  aria-relevant="additions text"
                  role="log"
                  className="css-7pg0cj-a11yText"
                />
                <div className="flex peer form-field block w-full px-3 py-1.5 border border-gray-900 bg-transparent rounded placeholder:text-gray-200 r-select__control css-ecmtp8-control">
                  <div className="r-select__value-container r-select__value-container--is-multi r-select__value-container--has-value css-xoxudw">
                    <div className="r-select__multi-value css-1p3m7a8-multiValue">
                      <div className="r-select__multi-value__label css-9jq23d">
                        Herren II
                      </div>
                      <div
                        role="button"
                        className="r-select__multi-value__remove css-v7duua"
                        aria-label="Remove Herren II"
                      >
                        <svg
                          height={14}
                          width={14}
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                          focusable="false"
                          className="css-8mmkcg"
                        >
                          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="r-select__input-container css-qub7q9"
                      data-value=""
                    >
                      <input
                        className="r-select__input"
                        style={{
                          color: "inherit",
                          background: "0px center",
                          opacity: 1,
                          width: "100%",
                          gridArea: "1 / 2",
                          font: "inherit",
                          minWidth: 2,
                          border: 0,
                          margin: 0,
                          outline: 0,
                          padding: 0
                        }}
                        autoCapitalize="none"
                        autoComplete="off"
                        autoCorrect="off"
                        id="react-select-teamIds_input-input"
                        spellCheck="false"
                        tabIndex={0}
                        aria-autocomplete="list"
                        // aria-expanded="false"
                        aria-haspopup="true"
                        // role="combobox"
                        type="text"
                        defaultValue=""
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="text-gray-900 r-select__indicators css-1wy0on6 hidden">
                    <div
                      className="r-select__indicator r-select__clear-indicator css-1xc3v61-indicatorContainer"
                      aria-hidden="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="h-4 w-4"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeWidth={2}
                          d="M5 19 19 5m0 14L5 5"
                        />
                      </svg>
                    </div>
                    <div
                      className="r-select__indicator r-select__dropdown-indicator css-h7zr3y-indicatorContainer"
                      aria-hidden="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="h-4 w-4"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeWidth={2}
                          d="M12 17 3.5 8.5m17 0L12 17"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <input type="hidden" defaultValue={31595} name="teamIds" /> */}
                </div>
              </div>
              <label
                htmlFor="teamIds"
                className="font-bold font-compressed peer-focus:text-secondary order-first block mb-1 "
              >
                Teilnehmer
              </label>
            </fieldset>
          </div>
          <fieldset className="fieldset flex flex-col ">
            <div className="react-datetime-picker react-datetime-picker--closed react-datetime-picker--enabled form-field w-full mt-1 ">
              <div className="react-datetime-picker__wrapper">
                <div className="react-datetime-picker__inputGroup">
                  {/* <input
                    hidden=""
                    max="275760-09-13T02:00"
                    min="0001-01-01T00:00"
                    step={60}
                    style={{
                      visibility: "hidden",
                      position: "absolute",
                      zIndex: -999
                    }}
                    type="datetime-local"
                    defaultValue=""
                    name="datetime"
                  /> */}
                  <input
                    autoComplete="off"
                    className="react-datetime-picker__inputGroup__input react-datetime-picker__inputGroup__day"
                    data-input="true"
                    inputMode="numeric"
                    max={31}
                    min={1}
                    placeholder="--"
                    type="number"
                    defaultValue=""
                    name="day"
                    style={{ width: 12 }}
                    readOnly
                  />
                  <span className="react-datetime-picker__inputGroup__divider">
                    .
                  </span>
                  <input
                    autoComplete="off"
                    className="react-datetime-picker__inputGroup__input react-datetime-picker__inputGroup__month"
                    data-input="true"
                    inputMode="numeric"
                    max={12}
                    min={1}
                    placeholder="--"
                    type="number"
                    defaultValue=""
                    name="month"
                    style={{ width: 12 }}
                    readOnly
                  />
                  <span className="react-datetime-picker__inputGroup__divider">
                    .
                  </span>
                  <input
                    autoComplete="off"
                    className="react-datetime-picker__inputGroup__input react-datetime-picker__inputGroup__year"
                    data-input="true"
                    inputMode="numeric"
                    max={275760}
                    min={1}
                    placeholder="----"
                    step={1}
                    type="number"
                    defaultValue=""
                    name="year"
                    style={{ width: 24 }}
                    readOnly
                  />
                </div>
                <button
                  className="react-datetime-picker__calendar-button react-datetime-picker__button"
                  type="button"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <g fill="currentColor" fillRule="evenodd">
                      <path d="M16 2v4a1 1 0 0 0 2 0V2a1 1 0 0 0-2 0M6 2v4a1 1 0 1 0 2 0V2a1 1 0 1 0-2 0M3 12h18a1 1 0 0 0 0-2H3a1 1 0 0 0 0 2" />
                      <path
                        fillRule="nonzero"
                        d="M19.5 3A2.5 2.5 0 0 1 22 5.5v15a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 2 20.5v-15A2.5 2.5 0 0 1 4.5 3zm0 2h-15a.5.5 0 0 0-.5.5v15c0 .28.22.5.5.5h15a.5.5 0 0 0 .5-.5v-15a.5.5 0 0 0-.5-.5"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            <label
              htmlFor="start"
              className="font-bold font-compressed peer-focus:text-secondary order-first block "
            >
              Datum
            </label>
          </fieldset>
        </div>
        <div className="bg-surface text-gray-900 rounded-md @container/card z-[11] p-4">
          <div className="flex gap-x-3">
            <fieldset className="fieldset flex flex-col flex-1">
              <div
                className="peer r-select css-b62m3t-container"
                id="recurring_frequency_input"
              >
                <span
                  id="react-select-recurring_frequency_input-live-region"
                  className="css-7pg0cj-a11yText"
                />
                <span
                  aria-live="polite"
                  aria-atomic="false"
                  aria-relevant="additions text"
                  role="log"
                  className="css-7pg0cj-a11yText"
                />
                <div className="flex peer form-field w-full px-3 py-2 border border-gray-900 bg-transparent rounded placeholder:text-gray-200 r-select__control css-ecmtp8-control
                justify-between items-center">
                  <div className="r-select__value-container r-select__value-container--has-value css-1m3xje4">
                    <div className="r-select__single-value css-1dimb5e-singleValue">
                      Jede Woche
                    </div>
                    <div
                      className="hidden r-select__input-container css-qub7q9"
                      data-value=""
                    >
                      <input
                        className="r-select__input"
                        style={{
                          color: "inherit",
                          background: "0px center",
                          opacity: 0,
                          width: "100%",
                          gridArea: "1 / 2",
                          font: "inherit",
                          minWidth: 2,
                          border: 0,
                          margin: 0,
                          outline: 0,
                          padding: 0
                        }}
                        autoCapitalize="none"
                        autoComplete="off"
                        autoCorrect="off"
                        id="react-select-recurring_frequency_input-input"
                        spellCheck="false"
                        tabIndex={0}
                        aria-autocomplete="list"
                        // aria-expanded="false"
                        aria-haspopup="true"
                        // role="combobox"
                        type="text"
                        defaultValue=""
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="text-gray-900 r-select__indicators css-1wy0on6">
                    <div
                      className="hidden r-select__indicator r-select__clear-indicator css-1xc3v61-indicatorContainer"
                      aria-hidden="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="h-4 w-4"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeWidth={2}
                          d="M5 19 19 5m0 14L5 5"
                        />
                      </svg>
                    </div>
                    <div
                      className="r-select__indicator r-select__dropdown-indicator css-h7zr3y-indicatorContainer flex !pr-0 !h-[1.75rem]"
                      aria-hidden="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="h-4 w-4"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeWidth={2}
                          d="M12 17 3.5 8.5m17 0L12 17"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {/* <input
                  type="hidden"
                  defaultValue="weekly"
                  name="recurring.frequency"
                /> */}
              </div>
              <label
                htmlFor="recurring.frequency"
                className="font-bold font-compressed peer-focus:text-secondary order-first block mb-1 "
              >
                Wiederholen
              </label>
            </fieldset>
            <fieldset className="fieldset flex flex-col ">
              <div className="react-datetime-picker react-datetime-picker--closed react-datetime-picker--enabled form-field w-full mt-1 ">
                <div className="react-datetime-picker__wrapper flex gap-2">
                  <div className="react-datetime-picker__inputGroup">
                    <input
                      hidden
                      max="275760-09-13T02:00"
                      min="0001-01-01T00:00"
                      step={60}
                      style={{
                        visibility: "hidden",
                        position: "absolute",
                        zIndex: -999
                      }}
                      type="datetime-local"
                      defaultValue={date.toISOString()}
                      name="datetime"
                      readOnly
                    />
                    {/* <span className="react-datetime-picker__inputGroup__leadingZero">
                      0
                    </span>
                    <input
                      autoComplete="off"
                      className="react-datetime-picker__inputGroup__input react-datetime-picker__inputGroup__day react-datetime-picker__inputGroup__input--hasLeadingZero"
                      data-input="true"
                      inputMode="numeric"
                      max={31}
                      min={1}
                      placeholder="--"
                      value={date.getDay()}
                      name="day"
                      style={{ width: 9 }}
                    />
                    <span className="react-datetime-picker__inputGroup__divider">
                      .
                    </span> */}
                    {/* <span className="react-datetime-picker__inputGroup__leadingZero">
                      0
                    </span> */}
                    {/* <input
                      autoComplete="off"
                      className="react-datetime-picker__inputGroup__input react-datetime-picker__inputGroup__month react-datetime-picker__inputGroup__input--hasLeadingZero"
                      data-input="true"
                      inputMode="numeric"
                      max={12}
                      min={1}
                      placeholder="--"
                      value={date.getMonth()}
                      name="month"
                      style={{ width: 6 }}
                    />
                    <span className="react-datetime-picker__inputGroup__divider">
                      .
                    </span> */}
                    <input
                      autoComplete="off"
                      className="react-datetime-picker__inputGroup__input react-datetime-picker__inputGroup__year"
                      data-input="true"
                      inputMode="numeric"
                      max={275760}
                      min={1}
                      placeholder="----"
                      step={1}
                      value={date.toLocaleDateString('de-DE')}
                      name="year"
                      style={{ width: 60 }}
                      readOnly
                    />
                  </div>
                  <button
                    className="react-datetime-picker__calendar-button react-datetime-picker__button"
                    type="button"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <g fill="currentColor" fillRule="evenodd">
                        <path d="M16 2v4a1 1 0 0 0 2 0V2a1 1 0 0 0-2 0M6 2v4a1 1 0 1 0 2 0V2a1 1 0 1 0-2 0M3 12h18a1 1 0 0 0 0-2H3a1 1 0 0 0 0 2" />
                        <path
                          fillRule="nonzero"
                          d="M19.5 3A2.5 2.5 0 0 1 22 5.5v15a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 2 20.5v-15A2.5 2.5 0 0 1 4.5 3zm0 2h-15a.5.5 0 0 0-.5.5v15c0 .28.22.5.5.5h15a.5.5 0 0 0 .5-.5v-15a.5.5 0 0 0-.5-.5"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
              <label
                htmlFor="recurring.until"
                className="font-bold font-compressed peer-focus:text-secondary order-first block "
              >
                Wiederholen Ende
              </label>
            </fieldset>
          </div>
        </div>
        <div className="bg-surface text-gray-900 rounded-md @container/card z-10 flex flex-col gap-y-3 p-4">
          <div>
            <div className="font-bold mb-1">Teilnahme</div>
            <fieldset className="fieldset ">
              <label className="font-bold font-compressed peer-focus:text-secondary flex w-full gap-x-2 items-center !font-normal">
                <input
                  className="input-checkbox w-5 h-5 shrink-0 appearance-none 
   bg-transparent border border-gray-900 rounded
   focus:border-secondary focus:outline-none focus:ring-1 focus:ring-secondary
   checked:border-secondary checked:bg-secondary
  mt-0.5 "
                  id="responseMode_checkbox"
                  type="checkbox"
                  name="responseMode_checkbox"
                  checked
                  readOnly
                />
                Teilnahme abfragen
              </label>
            </fieldset>
          </div>
          <fieldset className="fieldset flex flex-col flex-1">
            <div
              className="peer r-select css-b62m3t-container"
              id="responseMode_input"
            >
              <span
                id="react-select-responseMode_input-live-region"
                className="css-7pg0cj-a11yText"
              />
              <span
                aria-live="polite"
                aria-atomic="false"
                aria-relevant="additions text"
                role="log"
                className="css-7pg0cj-a11yText"
              />
              <div className="peer form-field w-full px-3 py-2 border border-gray-900 bg-transparent rounded placeholder:text-gray-200 r-select__control css-ecmtp8-control flex items-center justify-between">
                <div className="r-select__value-container r-select__value-container--has-value css-1m3xje4">
                  <div className="r-select__single-value css-1dimb5e-singleValue">
                    Zusage
                  </div>
                  <div
                    className="hidden r-select__input-container css-qub7q9"
                    data-value=""
                  >
                    <input
                      className="r-select__input"
                      style={{
                        color: "inherit",
                        background: "0px center",
                        opacity: 1,
                        width: "100%",
                        gridArea: "1 / 2",
                        font: "inherit",
                        minWidth: 2,
                        border: 0,
                        margin: 0,
                        outline: 0,
                        padding: 0
                      }}
                      autoCapitalize="none"
                      autoComplete="off"
                      autoCorrect="off"
                      id="react-select-responseMode_input-input"
                      spellCheck="false"
                      tabIndex={0}
                      aria-autocomplete="list"
                      // aria-expanded="false"
                      aria-haspopup="true"
                      // role="combobox"
                      type="text"
                      defaultValue=""
                      readOnly
                    />
                  </div>
                </div>
                <div className="text-gray-900 r-select__indicators css-1wy0on6">
                  <div
                    className="r-select__indicator r-select__dropdown-indicator css-h7zr3y-indicatorContainer"
                    aria-hidden="true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="h-4 w-4"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeWidth={2}
                        d="M12 17 3.5 8.5m17 0L12 17"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              {/* <input
                type="hidden"
                defaultValue="default-unknown"
                name="responseMode"
              /> */}
            </div>
            <div className="text-sm mt-1"><b>Zusage</b>: <span>Eingeladene Mitglieder nehmen automatisch an diesem Termin teil. Ist eine Teilnahme nicht möglich, müssen sie diesen Termin aktiv absagen.</span></div>
            <label
              htmlFor="responseMode"
              className="font-bold font-compressed peer-focus:text-secondary order-first block mb-1 "
            >
              Standard-Teilnahme
            </label>
          </fieldset>
        </div>
        <div className="bg-surface text-gray-900 rounded-md @container/card flex flex-col gap-y-3 p-4">
          <fieldset className="fieldset flex flex-col ">
            <input
              className="block w-full px-3 py-2.5 border border-gray-900 bg-transparent rounded focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary placeholder:text-gray-200 peer form-field w-full mt-1 "
              id="location_input"
              type="text"
              name="location"
              readOnly
            />
            <label
              htmlFor="location_input"
              className="font-bold font-compressed peer-focus:text-secondary order-first block "
            >
              Ort <small className="font-normal">(optional)</small>
            </label>
          </fieldset>
          <div className="!hidden flex flex-col">
            <div className="quill mt-1">
              <div className="ql-toolbar ql-snow">
                <span className="ql-formats">
                  <span className="ql-header ql-picker">
                    <span
                      className="ql-picker-label"
                      tabIndex={0}
                      role="button"
                      // aria-expanded="false"
                      aria-controls="ql-picker-options-1"
                    >
                      <svg viewBox="0 0 18 18">
                        {" "}
                        <polygon
                          className="ql-stroke"
                          points="7 11 9 13 11 11 7 11"
                        />{" "}
                        <polygon
                          className="ql-stroke"
                          points="7 7 9 5 11 7 7 7"
                        />{" "}
                      </svg>
                    </span>
                    <span
                      className="ql-picker-options"
                      aria-hidden="true"
                      tabIndex={-1}
                      id="ql-picker-options-1"
                    >
                      <span
                        tabIndex={0}
                        role="button"
                        className="ql-picker-item"
                        data-value={1}
                      />
                      <span
                        tabIndex={0}
                        role="button"
                        className="ql-picker-item"
                        data-value={2}
                      />
                      <span
                        tabIndex={0}
                        role="button"
                        className="ql-picker-item ql-selected"
                      />
                    </span>
                  </span>
                  <select className="ql-header" style={{ display: "none" }}>
                    <option value={1} />
                    <option value={2} />
                    {/* <option selected /> */}
                  </select>
                </span>
                <span className="ql-formats">
                  <button type="button" className="ql-bold">
                    <svg viewBox="0 0 18 18">
                      {" "}
                      <path
                        className="ql-stroke"
                        d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
                      />{" "}
                      <path
                        className="ql-stroke"
                        d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
                      />{" "}
                    </svg>
                  </button>
                  <button type="button" className="ql-italic">
                    <svg viewBox="0 0 18 18">
                      {" "}
                      <line
                        className="ql-stroke"
                        x1={7}
                        x2={13}
                        y1={4}
                        y2={4}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={5}
                        x2={11}
                        y1={14}
                        y2={14}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={8}
                        x2={10}
                        y1={14}
                        y2={4}
                      />{" "}
                    </svg>
                  </button>
                  <button type="button" className="ql-underline">
                    <svg viewBox="0 0 18 18">
                      {" "}
                      <path
                        className="ql-stroke"
                        d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"
                      />{" "}
                      <rect
                        className="ql-fill"
                        height={1}
                        rx="0.5"
                        ry="0.5"
                        width={12}
                        x={3}
                        y={15}
                      />{" "}
                    </svg>
                  </button>
                  <button type="button" className="ql-strike">
                    <svg viewBox="0 0 18 18">
                      {" "}
                      <line
                        className="ql-stroke ql-thin"
                        x1="15.5"
                        x2="2.5"
                        y1="8.5"
                        y2="9.5"
                      />{" "}
                      <path
                        className="ql-fill"
                        d="M9.007,8C6.542,7.791,6,7.519,6,6.5,6,5.792,7.283,5,9,5c1.571,0,2.765.679,2.969,1.309a1,1,0,0,0,1.9-.617C13.356,4.106,11.354,3,9,3,6.2,3,4,4.538,4,6.5a3.2,3.2,0,0,0,.5,1.843Z"
                      />{" "}
                      <path
                        className="ql-fill"
                        d="M8.984,10C11.457,10.208,12,10.479,12,11.5c0,0.708-1.283,1.5-3,1.5-1.571,0-2.765-.679-2.969-1.309a1,1,0,1,0-1.9.617C4.644,13.894,6.646,15,9,15c2.8,0,5-1.538,5-3.5a3.2,3.2,0,0,0-.5-1.843Z"
                      />{" "}
                    </svg>
                  </button>
                </span>
                <span className="ql-formats">
                  <button type="button" className="ql-list" value="ordered">
                    <svg viewBox="0 0 18 18">
                      {" "}
                      <line
                        className="ql-stroke"
                        x1={7}
                        x2={15}
                        y1={4}
                        y2={4}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={7}
                        x2={15}
                        y1={9}
                        y2={9}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={7}
                        x2={15}
                        y1={14}
                        y2={14}
                      />{" "}
                      <line
                        className="ql-stroke ql-thin"
                        x1="2.5"
                        x2="4.5"
                        y1="5.5"
                        y2="5.5"
                      />{" "}
                      <path
                        className="ql-fill"
                        d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"
                      />{" "}
                      <path
                        className="ql-stroke ql-thin"
                        d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"
                      />{" "}
                      <path
                        className="ql-stroke ql-thin"
                        d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"
                      />{" "}
                    </svg>
                  </button>
                  <button type="button" className="ql-list" value="bullet">
                    <svg viewBox="0 0 18 18">
                      {" "}
                      <line
                        className="ql-stroke"
                        x1={6}
                        x2={15}
                        y1={4}
                        y2={4}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={6}
                        x2={15}
                        y1={9}
                        y2={9}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={6}
                        x2={15}
                        y1={14}
                        y2={14}
                      />{" "}
                      <line className="ql-stroke" x1={3} x2={3} y1={4} y2={4} />{" "}
                      <line className="ql-stroke" x1={3} x2={3} y1={9} y2={9} />{" "}
                      <line
                        className="ql-stroke"
                        x1={3}
                        x2={3}
                        y1={14}
                        y2={14}
                      />{" "}
                    </svg>
                  </button>
                </span>
                <span className="ql-formats">
                  <button type="button" className="ql-link">
                    <svg viewBox="0 0 18 18">
                      {" "}
                      <line
                        className="ql-stroke"
                        x1={7}
                        x2={11}
                        y1={7}
                        y2={11}
                      />{" "}
                      <path
                        className="ql-even ql-stroke"
                        d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"
                      />{" "}
                      <path
                        className="ql-even ql-stroke"
                        d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"
                      />{" "}
                    </svg>
                  </button>
                </span>
                <span className="ql-formats">
                  <button type="button" className="ql-clean">
                    <svg className="" viewBox="0 0 18 18">
                      {" "}
                      <line
                        className="ql-stroke"
                        x1={5}
                        x2={13}
                        y1={3}
                        y2={3}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={6}
                        x2="9.35"
                        y1={12}
                        y2={3}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={11}
                        x2={15}
                        y1={11}
                        y2={15}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={15}
                        x2={11}
                        y1={11}
                        y2={15}
                      />{" "}
                      <rect
                        className="ql-fill"
                        height={1}
                        rx="0.5"
                        ry="0.5"
                        width={7}
                        x={2}
                        y={14}
                      />{" "}
                    </svg>
                  </button>
                </span>
              </div>
              <div className="ql-container ql-snow">
                <div
                  className="ql-editor ql-blank"
                  data-gramm="false"
                  // contentEditable="true"
                >
                  <p>
                    <br />
                  </p>
                </div>
                <div
                  className="ql-clipboard"
                  // contentEditable="true"
                  tabIndex={-1}
                />
                <div className="ql-tooltip ql-hidden">
                  <a
                    className="ql-preview"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="about:blank"
                  />
                  <input
                    type="text"
                    data-formula="e=mc^2"
                    data-link="https://quilljs.com"
                    data-video="Embed URL"
                  />
                  <a className="ql-action" />
                  <a className="ql-remove" />
                </div>
              </div>
            </div>
            <label
              htmlFor="message"
              className="font-bold font-compressed peer-focus:text-secondary order-first"
            >
              Weitere Informationen{" "}
              <small className="font-normal">(optional)</small>
            </label>
          </div>
          <fieldset className="fieldset ">
            <label className="font-bold font-compressed peer-focus:text-secondary flex w-full gap-x-2 items-center ">
              <input
                className="input-checkbox w-5 h-5 shrink-0 appearance-none 
   bg-transparent border border-gray-900 rounded
   focus:border-secondary focus:outline-none focus:ring-1 focus:ring-secondary
   checked:border-secondary checked:bg-secondary
  mt-0.5 "
                id="event-form-public-visible"
                type="checkbox"
                name="publicVisible"
                readOnly
              />
              Öffentlich sichtbar
            </label>
          </fieldset>
        </div>
      </div>
      <div className="mt-6">
        <fieldset className="fieldset flex flex-col flex-1">
          <div
            className="peer r-select css-b62m3t-container"
            id="notifications_input"
          >
            <span
              id="react-select-notifications_input-live-region"
              className="css-7pg0cj-a11yText"
            />
            <span
              aria-live="polite"
              aria-atomic="false"
              aria-relevant="additions text"
              role="log"
              className="css-7pg0cj-a11yText"
            />
            <div className="flex peer form-field block w-full px-3 py-1.5 border border-gray-900 bg-transparent rounded placeholder:text-gray-200 r-select__control css-ecmtp8-control">
              <div className="r-select__value-container r-select__value-container--has-value css-1m3xje4">
                <div className="r-select__single-value css-1dimb5e-singleValue">
                  <div className="flex gap-1.5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-4 h-4 inline-block shrink-0 relative top-1"
                    >
                      <g fill="none" fillRule="evenodd" stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="m1 1 22 22"
                        />
                        <path
                          strokeLinecap="round"
                          strokeWidth={2}
                          d="M12 13v6m0-6-4.144 2.244M3.472 4.563a12 12 0 0 0-1.841 2.401M17.608 2.39a12.07 12.07 0 0 1 4.762 4.574"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="m1 1 22 22"
                        />
                        <path
                          strokeWidth="1.895"
                          d="M18.789 18.111q-.331.439-.716.83l-1.023.897A8.5 8.5 0 0 1 12 21.5a8.5 8.5 0 0 1-5.05-1.662l-1.023-.897A8.44 8.44 0 0 1 3.5 13.004c0-2.774 1.33-5.24 3.388-6.793"
                        />
                        <path
                          strokeLinecap="round"
                          strokeWidth="1.895"
                          d="M10.142 4.704A8.5 8.5 0 0 1 12 4.5c4.695 0 8.5 3.812 8.5 8.504 0 .65-.073 1.281-.211 1.888"
                        />
                      </g>
                    </svg>
                    <div>
                      <div className="">Keine Benachrichtigung</div>
                    </div>
                  </div>
                </div>
                <input
                  id="react-select-notifications_input-input"
                  tabIndex={0}
                  inputMode="none"
                  aria-autocomplete="list"
                  // aria-expanded="false"
                  aria-haspopup="true"
                  // role="combobox"
                  aria-readonly="true"
                  className="css-1hac4vs-dummyInput"
                  defaultValue=""
                  readOnly
                />
              </div>
              <div className="text-gray-900 r-select__indicators css-1wy0on6">
                <div
                  className="r-select__indicator r-select__dropdown-indicator css-h7zr3y-indicatorContainer"
                  aria-hidden="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-4 w-4"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeWidth={2}
                      d="M12 17 3.5 8.5m17 0L12 17"
                    />
                  </svg>
                </div>
              </div>
            </div>
            {/* <input type="hidden" defaultValue="none" name="notifications" /> */}
          </div>
        </fieldset>
        <button
          className="

button
button--primary

flex justify-center w-full mt-2"
          type="submit"
        >
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 448 512"
            className="mr-blank"
          >
            <path
              fill="currentColor"
              d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32v144H48c-17.7 0-32 14.3-32 32s14.3 32 32 32h144v144c0 17.7 14.3 32 32 32s32-14.3 32-32V288h144c17.7 0 32-14.3 32-32s-14.3-32-32-32H256z"
            />
          </svg>
          Termin erstellen
        </button>
      </div>
    </form>
  </div>
</div>

  </>
}
