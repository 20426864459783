import { getDateWithDay } from "@/helpers/dateHelper";

export default function PollResult() {
  return <>
    <div className="bg-surface text-gray-900 rounded-md @container/card  ">
  <div className="flex flex-col justify-center items-start px-3 pt-3 duration-fast hover:bg-gray-100/50 active:bg-gray-100 pb-4">
    <div className="flex items-baseline gap-1 mb-2 flex-wrap">
      <a
        className="bg-gray-900 text-gray-50 rounded px-1.5 font-bold uppercase pb-px text-sm"
        href="/team/34985/umfragen"
      >
        D-Junioren (U13) I
      </a>
    </div>
    <a href="/umfrage/66179c67574bc3e3f8fa4555">
      <h3 className="text-xl font-semibold">Grillfest zum Saisonabschluss?</h3>
      {/* <div className="flex items-center justify-center gap-2 mt-0.5">
        <span className="">Beendet vor 250 Tagen 17h 8m</span>
      </div> */}
    </a>
  </div>
  <hr className="border-gray-150" />
  <a href="/umfrage/66179c67574bc3e3f8fa4555">
    <div className="bg-surface text-gray-900 rounded-md @container/card mt-4 ">
      <div>
        <div className="relative pointer-events-none">
          <div className="flex flex-col divide-y divide-gray-150">
            <div className="px-2">
              <div className="relative w-full pr-2 px-2">
                <div className="relative py-4">
                  <div className="flex grow justify-between items-center w-full z-10 relative">
                    <div className="flex items-center justify-between grow ">
                      <div className="px-2 font-semibold">{getDateWithDay(new Date(new Date().setDate(new Date().getDate() + 15)).setHours(13, 30, 0, 0)/1000)} </div>
                      <div className="flex flex-row-reverse pr-0.5" />
                    </div>
                    <div className="w-11 shrink-0 flex justify-end text-right">
                      <div className="font-normal text-muted font-condensed text-right">
                        (18)
                      </div>
                    </div>
                  </div>
                  <div className="flex grow justify-between items-center w-full z-20 absolute left-0 right-0 top-2 bottom-2">
                    <div
                      className="grow relative h-full"
                      style={{ clipPath: "inset(0px 0% 0px 0px)" }}
                    >
                      <div
                        className="h-full rounded absolute top-0 left-0 bottom-0 bg-team"
                        style={{ width: "100%" }}
                      />
                      <div className="flex items-center justify-between grow relative z-20 h-full text-team-contrast">
                        <div className="px-2 font-semibold">{getDateWithDay(new Date(new Date().setDate(new Date().getDate() + 15)).setHours(13, 30, 0, 0)/1000)} </div>
                        <div className="flex flex-row-reverse pr-0.5" />
                      </div>
                    </div>
                    <div className="w-11 shrink-0 " />
                  </div>
                </div>
              </div>
            </div>
            <div className="px-2">
              <div className="relative w-full pr-2 px-2">
                <div className="relative py-4">
                  <div className="flex grow justify-between items-center w-full z-10 relative">
                    <div className="flex items-center justify-between grow ">
                      <div className="px-2 font-semibold">{getDateWithDay(new Date(new Date().setDate(new Date().getDate() + 16)).setHours(13, 30, 0, 0)/1000)} </div>
                      <div className="flex flex-row-reverse pr-0.5" />
                    </div>
                    <div className="w-11 shrink-0 flex justify-end text-right">
                      <div className="font-normal text-muted font-condensed text-right">
                        (12)
                      </div>
                    </div>
                  </div>
                  <div className="flex grow justify-between items-center w-full z-20 absolute left-0 right-0 top-2 bottom-2">
                    <div
                      className="grow relative h-full"
                      style={{ clipPath: "inset(0px 40% 0px 0px)" }}
                    >
                      <div
                        className="h-full rounded absolute top-0 left-0 bottom-0 bg-gray-150"
                        style={{ width: "60%" }}
                      />
                      <div className="flex items-center justify-between grow relative z-20 h-full ">
                        <div className="px-2 font-semibold">{getDateWithDay(new Date(new Date().setDate(new Date().getDate() + 16)).setHours(13, 30, 0, 0)/1000)} </div>
                        <div className="flex flex-row-reverse pr-0.5" />
                      </div>
                    </div>
                    <div className="w-11 shrink-0 " />
                  </div>
                </div>
              </div>
            </div>
            <div className="px-2">
              <div className="relative w-full pr-2 px-2">
                <div className="relative py-4">
                  <div className="flex grow justify-between items-center w-full z-10 relative">
                    <div className="flex items-center justify-between grow ">
                      <div className="px-2 font-semibold">{getDateWithDay(new Date(new Date().setDate(new Date().getDate() + 17)).setHours(13, 30, 0, 0)/1000)} </div>
                      <div className="flex flex-row-reverse pr-0.5" />
                    </div>
                    <div className="w-11 shrink-0 flex justify-end text-right">
                      <div className="font-normal text-muted font-condensed text-right">
                        (10)
                      </div>
                    </div>
                  </div>
                  <div className="flex grow justify-between items-center w-full z-20 absolute left-0 right-0 top-2 bottom-2">
                    <div
                      className="grow relative h-full"
                      style={{ clipPath: "inset(0px 50% 0px 0px)" }}
                    >
                      <div
                        className="h-full rounded absolute top-0 left-0 bottom-0 bg-gray-150"
                        style={{ width: "50%" }}
                      />
                      <div className="flex items-center justify-between grow relative z-20 h-full ">
                        <div className="px-2 font-semibold">{getDateWithDay(new Date(new Date().setDate(new Date().getDate() + 17)).setHours(13, 30, 0, 0)/1000)} </div>
                        <div className="flex flex-row-reverse pr-0.5" />
                      </div>
                    </div>
                    <div className="w-11 shrink-0 " />
                  </div>
                </div>
              </div>
            </div>
            <div className="px-2">
              <div className="relative w-full pr-2 px-2">
                <div className="relative py-4">
                  <div className="flex grow justify-between items-center w-full z-10 relative">
                    <div className="flex items-center justify-between grow ">
                      <div className="px-2 font-semibold">{getDateWithDay(new Date(new Date().setDate(new Date().getDate() + 18)).setHours(13, 30, 0, 0)/1000)} </div>
                      <div className="flex flex-row-reverse pr-0.5" />
                    </div>
                    <div className="w-11 shrink-0 flex justify-end text-right">
                      <div className="font-normal text-muted font-condensed text-right">
                        (3)
                      </div>
                    </div>
                  </div>
                  <div className="flex grow justify-between items-center w-full z-20 absolute left-0 right-0 top-2 bottom-2">
                    <div
                      className="grow relative h-full"
                      style={{ clipPath: "inset(0px 60% 0px 0px)" }}
                    >
                      <div
                        className="h-full rounded absolute top-0 left-0 bottom-0 bg-gray-150"
                        style={{ width: "40%" }}
                      />
                      <div className="flex items-center justify-between grow relative z-20 h-full ">
                        <div className="px-2 font-semibold">{getDateWithDay(new Date(new Date().setDate(new Date().getDate() + 18)).setHours(13, 30, 0, 0)/1000)} </div>
                        <div className="flex flex-row-reverse pr-0.5" />
                      </div>
                    </div>
                    <div className="w-11 shrink-0 " />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute z-50 bg-gradient-to-b from-transparent to-gray-50 h-full bottom-2 right-0 left-0 pointer-events-none" />
        </div>
      </div>
    </div>
    <div className="pb-0.5 px-0.5 border-t border-gray-150">
      <button
        className="
inline-flex
button
button--link-secondary

justify-center w-full rounded-t-none hover:rounded-t"
      >
        {" "}
        Abstimmung anzeigen
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="button__icon ml-blank"
        >
          <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={2}
            d="M16.5 12 8 3.5m0 17 8.5-8.5"
          />
        </svg>
      </button>
    </div>
  </a>
</div>

  </>
}
