import { getAge } from "@/helpers/dateHelper";
import { getFlagCode } from "@/helpers/nationshelper";
import { getPersonCountryData } from "@/helpers/personHelpers";
import { formatDate } from "@/utils/datetimeUtils";
import { pwaTeamPlayerPath } from "@/utils/urlHelper";
import Link from "next/link";
import Flag from "react-world-flags";
import { PlayerCardSmallProps } from "./types";

function PlayerCardSmallDataWrapper({ player,
  teamId,
  widget, children }: PlayerCardSmallProps) {

  return widget ? <div className="block">
    {children}
  </div> : <Link href={pwaTeamPlayerPath(teamId.toString(), player.Id.toString())} className="block">{children}</Link>
}

export function PlayerCardSmallData({ player,
  ...rest }: PlayerCardSmallProps) {
  const isInFuture =
    player.InTeamSince && new Date(player.InTeamSince) > new Date();

  const countryCode = getPersonCountryData(player);

  return <PlayerCardSmallDataWrapper player={player} {...rest}>
    <div className={"text-ellipsis overflow-hidden"}>
      {player.FirstName}
    </div>
    <div className="text-ellipsis overflow-hidden text-lg font-semibold">
      {player.LastName}
    </div>
    {(player.Birthdate || countryCode) && (
      <div className="flex items-center space-x-2">
        {countryCode?.code && (<Flag
            code={getFlagCode(countryCode.code)}
            width={24}
            height={24}
            className="w-6 h-6 object-contain"
          />
        )}
        {player.Birthdate && (
          <div className={`text-gray-200 font-semibold`}>
            {getAge(new Date(player.Birthdate))} Jahre
          </div>
        )}
      </div>
    )}
    {isInFuture && (
      <div className="text-sm">Im Team ab: {formatDate(player.InTeamSince!)}</div>
    )}
  </PlayerCardSmallDataWrapper>
}
