"use client";
import { HomeTeamProps, useHomeTeamName } from "@/hooks/useHomeTeamName";
import React from "react";


function TeamTypeName(props: HomeTeamProps) {
  const teamName = useHomeTeamName(props);

  return <>{teamName}</>;
}

export function TeamName({ team, shortName, teamTypeName, ...props }: HomeTeamProps) {
  if (teamTypeName) {
    return <TeamTypeName {...{ team, shortName, teamTypeName, props }} />
  }

  if (shortName) {
    return <>{team?.ShortName}</>;
  }

  return <>{team?.OfficalName}</>;
}
