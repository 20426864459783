import CheckList, { CheckListItem } from "@/app/pwa/components/CheckList";
import { OnboardingTag, OnboardingSlide, OnboardingScreenshot } from "../OnboardingSlider";
import TrainingForm from "../Features/TraingForm";


import BildFansS from "@/assets/onboarding/bild-fans_s.webp";
import BildFansM from "@/assets/onboarding/bild-fans_m.webp";
import BildFansL from "@/assets/onboarding/bild-fans_l.webp";
import Grafikgenerator from "../Features/Grafikgenerator";
import NewsPage from "../Features/NewsPage";

export default function SlideFans() {
  return <>
    <OnboardingSlide
      imageContent={
        <>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img className="w-full h-full object-cover"
            src={BildFansS.src}
            alt=""
            sizes="(min-width: 30em) 30rem ,100vw"
            srcSet={`${BildFansS.src} 400w, ${BildFansM.src} 500w, ${BildFansL.src} 600w,`} />
          <OnboardingScreenshot className="flex justify-start" screenshotClassName="origin-bottom-right -rotate-[3deg] scale-75 w-[380px] h-[450px] max-h-[100%] -left-24">
            <NewsPage />
          </OnboardingScreenshot>
        </>
      }
      textContent={
        <div>
          <OnboardingTag>für Fans und Freunde</OnboardingTag>
          <h2 className="text-center text-balance text-xl font-bold mt-2 max-w-[16rem] mx-auto">Teams, Ticker, Termine – Dein Verein. Deine App</h2>
          <CheckList className="mt-2 max-w-[10rem] mx-auto">
            <CheckListItem>Alle News auf einen Blick</CheckListItem>
            <CheckListItem>Spielpläne & Tabellen</CheckListItem>
            <CheckListItem>Social Media Posts</CheckListItem>
            <CheckListItem>Push-Benachrichtigung</CheckListItem>
            <CheckListItem>Liveticker</CheckListItem>
          </CheckList>
        </div>
      }
    />

  </>
}
