import { Location, Person, PersonData, SquadPositionPlayer } from "@/sources/kicker-amateur/types";

export const getPersonCurrentFunction = (person: Person) => {
  const activeFunctions = getPersonActiveFunctions(person);

  return activeFunctions.length > 0 ? activeFunctions[0] : undefined;
};

export const getPersonActiveFunctions = (person: Person) => {
  if (!person.Function) return [];
  if (person.Function.length === 0) return [];

  const now = new Date().getTime();

  const activeFunctions = person.Function.filter((func) => (!func.End || Date.parse(func.End) > now));

  activeFunctions.sort((a, b) => b.Id! - a.Id!);

  return activeFunctions;
};

export const getPersonFullName = (person: Person) => {
  return [person?.FirstName, person?.LastName].join(" ");
};

export const getPersonCurrentFunctionName = (person: Person) => {
  const func = getPersonCurrentFunction(person);
  if (!func) return undefined;

  return func.FunctionType?.OfficalName;
};

const getPersonTeamFunctions = (person: Person, teamId?: number) => {
  if(person?.Function) {
    if(teamId){
      return person.Function?.filter(func => func.OrganizationId !== teamId)
    }
    return person.Function
  }
  return []
};

export const getPersonTeamFunctionNames = (person: Person, teamId?: number) => {
  const functions = getPersonTeamFunctions(person, teamId).filter(func => func.FunctionType && !func.End)

  return functions?.map(func => func.FunctionType?.OfficalName)
};

function createPersonCountryData (c: Location) {
  return {
    code:  c.NameCode,
    name: c.Name
  }
}

export function getPersonCountryData(person: Person | SquadPositionPlayer | PersonData){

  if(person && person.Country){
    return createPersonCountryData(person.Country)
  }

  if(person && person.Country2){
    return createPersonCountryData(person.Country2)
  }

  return
};
