import CheckList, { CheckListItem } from "@/app/pwa/components/CheckList";
import { OnboardingFeature, OnboardingScreenshot, OnboardingSlide, OnboardingTag } from "../OnboardingSlider";

import BildTrainerS from "@/assets/onboarding/bild-trainer_s.webp";
import BildTrainerM from "@/assets/onboarding/bild-trainer_m.webp";
import BildTrainerL from "@/assets/onboarding/bild-trainer_l.webp";
import TrainingForm from "../Features/TraingForm";
import Participate from "../Features/Participate";

export default function SlideTrainer() {
  return <>
    <OnboardingSlide 
      imageContent={
        <>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img className="w-full h-full object-cover object-left"
            src={BildTrainerS.src}
            alt=""
            sizes="(min-width: 30em) 30rem ,100vw"
            srcSet={`${BildTrainerS.src} 400w, ${BildTrainerM.src} 500w, ${BildTrainerL.src} 600w,`} />
          <OnboardingScreenshot className="flex justify-end" screenshotClassName="scale-[75%] w-[360px] h-[400px] max-h-[100%] right-[-100px] rotate-2">
            <TrainingForm />
          </OnboardingScreenshot>
          <OnboardingFeature className="scale-[80%] bottom-[3%] left-[-3%] -rotate-2"><Participate /></OnboardingFeature>
        </>
      }
      textContent={
        <div className="min-h-[30%]">
          <OnboardingTag>für Trainer</OnboardingTag>
          <h2 className="text-center text-balance text-xl font-bold mt-2 max-w-[20rem] mx-auto">Von der Planung bis zum Anpfiff: Volle Übersicht als Coach</h2>
          <CheckList className="mt-2 max-w-[10rem] mx-auto">
            <CheckListItem>Trainingsgestaltung</CheckListItem>
            <CheckListItem>Termine und Umfragen</CheckListItem>
            <CheckListItem>Kaderübersicht und Statistken</CheckListItem>
          </CheckList>
        </div>
      }
    />
  </>
}
