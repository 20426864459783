import { Team } from "@/sources/kicker-amateur/types";
import { integerToRoman } from "@/utils/romanNumbers";

export function getTeamName(team: Team) {
  return [team?.TeamRank, team?.TeamType?.Name].join(". ");
}

export function getTeamTypeName(team: Team) {
  if (!(team?.TeamType?.Name && team?.TeamRank)) {
    return team.OfficalName || "";
  }
  
  return [team?.TeamType?.Name, integerToRoman(team?.TeamRank)].join(" ");
}
