"use client";

import {Club, Person, Team} from "@/sources/kicker-amateur/types";
import React, { useState } from "react";

interface PWAContextType {
  pwa: Club;
  teams: Team[];
  persons: Person[];
}

const PwaContext = React.createContext<PWAContextType>({ pwa: {}, teams: [], persons: [] });

export default function PWAProvider({
  pwa,
  teams,
  persons,
  children,
}: {
  pwa: Club;
  teams: Team[];
  persons: Person[];
  children: React.ReactNode;
}) {
  return (
    <PwaContext.Provider value={{ pwa, teams, persons }}>
      {children}
    </PwaContext.Provider>
  );
}

export function useTeam(id: number|undefined){
  const {teams} = React.useContext(PwaContext);
  if(!id) return;
  return teams.find(team => team.Id === id);
}

export function useTeams(){
  const {teams} = React.useContext(PwaContext);
  return teams;
}



export function useActiveTeams(){
  const {teams} = React.useContext(PwaContext);
  return teams.filter(team => team.Active);
}

export function usePwa(){
  const {pwa} = React.useContext(PwaContext);
  return pwa;
}

export function useUserPersons(){
  const {persons} = React.useContext(PwaContext);
  return persons;
}
