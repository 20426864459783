import { OnboardingFeature, OnboardingScreenshot, OnboardingSlide, OnboardingTag } from "../OnboardingSlider";
import { PlayerCardSmallStatic } from "@/app/pwa/components/player/PlayerCardSmall";

import BildSpielerS from "@/assets/onboarding/bild-spieler_s.webp";
import BildSpielerM from "@/assets/onboarding/bild-spieler_m.webp";
import BildSpielerL from "@/assets/onboarding/bild-spieler_l.webp";
import { PersonData } from "@/sources/kicker-amateur/types";
import CheckList, { CheckListItem } from "@/app/pwa/components/CheckList";
import { TeamContestTableImp } from "@/app/pwa/components/team/TeamContestTable";
import Training from "../Features/Training";
// import { loadPwaClubTeams } from "@/sources/kicker-amateur/api";

export default function SlidePlayers({player, teamId, contestId}: {player:PersonData, teamId:number, contestId:number}) {
  

  return <>
    <OnboardingSlide 
      imageContent={
        <>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img className="w-full h-full object-cover"
            src={BildSpielerS.src}
            alt=""
            sizes="(min-width: 30em) 30rem ,100vw"
            srcSet={`${BildSpielerS.src} 400w, ${BildSpielerM.src} 500w, ${BildSpielerL.src} 600w,`} />
          <OnboardingScreenshot className="flex justify-end" screenshotClassName="origin-bottom-left rotate-[3deg] scale-75 w-[400px] h-[300px] max-h-[100%] -right-[16rem]">
            {/* // TODO:Timo! TODO:Emanuel! please check why table doesnt show correct data! team is always 5th and all data like points is 0 */}
            <TeamContestTableImp
              range={2}
              contestId={contestId}
              teamId={teamId}
            />
          </OnboardingScreenshot>
          <OnboardingFeature className="scale-75 rotate-1 top-4 right-[-10%]">
            <Training />
          </OnboardingFeature>
          <OnboardingFeature className="left-[5%] bottom-[5%] max-w-48 h-[91px] scale-75 -rotate-2">
            {/* TODO:Timo Player */}
            <PlayerCardSmallStatic player={player} teamId={teamId}  />
          </OnboardingFeature>
        </>
      }
      textContent={
        <div className="">
          <OnboardingTag>für Spieler</OnboardingTag>
          <h2 className="text-center text-balance text-xl font-bold mt-2 max-w-[20rem] mx-auto">Vernetzt, informiert, beteiligt: Alles, was du als Spieler brauchst</h2>
          <CheckList className="mt-2 max-w-[14rem] mx-auto">
            <CheckListItem>Spielerprofile</CheckListItem>
            <CheckListItem>Daten und Statistiken</CheckListItem>
            <CheckListItem>Ergebnisse und Tabellen</CheckListItem>
            <CheckListItem>Termine und Umfragen</CheckListItem>
            <CheckListItem>Vereinsnews</CheckListItem>
          </CheckList>
        </div>
      }
    />
  </>
}
