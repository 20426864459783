import { OnboardingSlide } from "../OnboardingSlider";
import Logo from "@/assets/branding/logo.svg";
import { ClubImage } from "@/app/pwa/components/TeamImage";

import BildVereinsheimS from "@/assets/onboarding/bild-vereinsheim_s.webp";
import BildVereinsheimM from "@/assets/onboarding/bild-vereinsheim_m.webp";
import BildVereinsheimL from "@/assets/onboarding/bild-vereinsheim_l.webp";
import { Club } from "@/sources/kicker-amateur/types";

export default function SlideWelcome({pwa, clubContext} : {pwa:Club, clubContext?: boolean}) {
  return <>
    <OnboardingSlide 
      imageContent={
        <>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img className="w-full h-full object-cover"
            src={BildVereinsheimS.src}
            alt=""
            sizes="(min-width: 30em) 30rem ,100vw"
            srcSet={`${BildVereinsheimS.src} 400w, ${BildVereinsheimM.src} 500w, ${BildVereinsheimL.src} 600w,`} />
          <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-evenly items-center py-[10%]">
            <div className="rounded-lg bg-white-forced shadow-lg p-2">
              <div className="bg-primary-forced p-2">
                <Logo className="w-[200px] h-[48px]"/>
              </div>
            </div>
            {clubContext &&
              <div className="bg-white-forced rounded-lg shadow-lg p-2">
                <ClubImage club={pwa} size="24" />
              </div>
            }
          </div>
        </>
      }
      textContent={
        <div className="text-center">
          <div className="font-semibold">Willkommen im neuen kicker-Vereinsheim!</div>
          <h2 className="text-center text-xl font-bold max-w-[16rem] mx-auto">
          Die App für deinen Verein
          </h2>
          <p className="mt-2">
          Vereinsverwaltung wie bei den Profis! Team-Management, Spiele und Tabellen, Terminverwaltung und Kommunikation. Was die App so kann? Wir stellen dir die wichtigsten Funktionen vor.
          </p>
        </div>
      }
    />
  </>
}
