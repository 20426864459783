"use client"

import Button from "@/app/pwa/components/Button";
import { useState } from "react";

import IconCopy from "@/assets/icon/copy.svg";
import IconCheckmark from "@/assets/icon/checkmark.svg";

export default function CopyButton({url} : {url:string}) {
  const [isCopied, setIsCopied] = useState(false);

  async function copy() {
    if (!isCopied) {
      try {
        await navigator.clipboard.writeText(url);
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      } catch (e) {
      }
    }
  }
  return <>

  {!isCopied ? (
    
    <Button color="primary-variant-strong" onClick={() => copy()}
    className="border border-transparent">
      <IconCopy className="mr-blank" />
      Link zum Vereinsheim kopieren
    </Button>
  ) : (
    <div className="">
      
      <Button color="primary"
        onClick={() => setIsCopied(false)}
        className="border border-white-forced"
      >
        <IconCheckmark className="mr-blank" />
        Link wurde kopiert!
      </Button>
    </div>
  )}
  </>
}
