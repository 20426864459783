import CheckList, { CheckListItem } from "@/app/pwa/components/CheckList";
import { OnboardingTag, OnboardingSlide, OnboardingFeature, OnboardingScreenshot } from "../OnboardingSlider";

import BildElternS from "@/assets/onboarding/bild-eltern_s.webp";
import BildElternM from "@/assets/onboarding/bild-eltern_m.webp";
import BildElternL from "@/assets/onboarding/bild-eltern_l.webp";
import PollResult from "../Features/PollResult";
import Grafikgenerator from "../Features/Grafikgenerator";
import TrainingCalendar from "../Features/TrainingCalendar";

export default function SlideParents() {
  return <>
    <OnboardingSlide
      imageContent={
        <>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img className="w-full h-full object-cover object-left-top"
            src={BildElternS.src}
            alt=""
            sizes="(min-width: 30em) 30rem ,100vw"
            srcSet={`${BildElternS.src} 400w, ${BildElternM.src} 500w, ${BildElternL.src} 600w,`} />
          <OnboardingScreenshot screenshotClassName="h-[80%] w-[375px] scale-[80%] -rotate-2 -bottom-[1.5rem]">
            <TrainingCalendar />
          </OnboardingScreenshot>
          <OnboardingFeature className="h-[300px] w-[230px] scale-75 absolute right-[-2.5rem] bottom-[5%] rotate-3">
            <PollResult />
          </OnboardingFeature>
        </>
      }
      textContent={
        <div>
          <OnboardingTag>für Eltern</OnboardingTag>
          <h2 className="text-center text-balance text-xl font-bold mt-2 max-w-[19rem] mx-auto">Termine, Abstimmung, Aufgaben: So bleiben Eltern immer am Ball</h2>
          <CheckList className="mt-2 max-w-[17rem] mx-auto">
            <CheckListItem>Terminübersicht</CheckListItem>
            <CheckListItem>Umfragen und Abstimmungen</CheckListItem>
            <CheckListItem>Organisation</CheckListItem>
          </CheckList>
        </div>
      }
    />
  </>
}
