// "use client"
import { Club } from "@/sources/kicker-amateur/types";
// import { ClubImage } from "@/app/pwa/components/TeamImage";
import Logo from "@/assets/branding/logo.svg";
import Button from "@/app/pwa/components/Button";

export default function SlideLetsGo({pwa, homeUrl, onButtonClick} : {pwa:Club, homeUrl: string, onButtonClick?: Function}) {
  return <>
    <div className="bg-gradient-to-b from-primary-forced to-primary-variant-forced flex flex-col items-center justify-center h-full text-white-forced pb-[7.5rem]">
      {/* <div className="bg-white-forced rounded-lg p-1.5">
        <ClubImage club={pwa} size="16" />
      </div> */}
      <div>
        <Logo className="w-[200px] h-[48px]"/>
      </div>
      <div className="mt-10">
        <h3 className="text-xl font-bold text-center px-6">Jetzt kostenlos loslegen: Das kicker-Vereinsheim für deinen Verein</h3>
        <div className="text-lg mt-6 grid grid-cols-[max-content_max-content] justify-center items-baseline gap-x-1.5 gap-y-2.5">
          <span className="bg-gray-50 rounded-full text-gray-900 font-bold w-5 h-5 flex justify-center items-center">1</span>
          <div>Verein suchen</div>
          <span className="bg-gray-50 rounded-full text-gray-900 font-bold w-5 h-5 flex justify-center items-center">2</span>
          <div>App auf dem Homescreen installieren</div>
          <span className="bg-gray-50 rounded-full text-gray-900 font-bold w-5 h-5 flex justify-center items-center">3</span>
          <div>Verein verwalten oder Mitglied werden</div>
        </div>
      </div>

      <div className="mt-7 text-center text-lg">
        <Button color="primary-variant-strong" onClick={() => {onButtonClick && onButtonClick()}}>
          Los geht&apos;s
        </Button>
      </div>
      
    </div>
  </>
}
