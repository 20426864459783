export const ONE_MINUTE = 60;

export const FIVE_MINUTES = 5 * ONE_MINUTE;
export const ONE_HOUR = 60 * 60;
export const SIX_HOURS = 6 * 60 * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_WEEK = ONE_DAY * 7;
export const ONE_MONTH = ONE_DAY * 30;

export function getDate(date: number) {
  return new Date(date * 1000).toLocaleDateString("de-De", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

export function getDateInOneYear() {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  return date;
}

export function getTime(date: number | string) {
  return `${new Date(date).getHours().toString().padStart(2, "0")}:${new Date(
    date
  )
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
}

export function getDateWithDay(date: number, excludeYear:boolean=false) {
  // return new Date(date * 1000).toLocaleDateString("de-De", {
  //   weekday: "short",
  //   year: "2-digit",
  //   month: "2-digit",
  //   day: "2-digit",
  // });

  // toLocalDateString is not consistend in browser for this case. (in safari is a "," missing)

  const days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
  const d = new Date(date * 1000);

  const day = days[d.getDay()];
  const year = d.getFullYear().toString().slice(-2); // Get last 2 digits of year
  const month = ('0' + (d.getMonth() + 1)).slice(-2); // Format month with leading zero
  const dayOfMonth = ('0' + d.getDate()).slice(-2); // Format day with leading zero

  return `${day}., ${dayOfMonth}.${month}${!excludeYear ? `.${year}` : ""}`;
}

export function getDateWithDayShort(date: number) {
  return new Date(date * 1000)
    .toLocaleDateString("de-De", {
      weekday: "short",
      day: "2-digit",
      month: "2-digit",
    })
    .replace(",", "");
}

export function getDateWithDayLong(date: number | string){
  return new Date(date)
    .toLocaleDateString("de-De", {
      weekday: "long",
      day: "2-digit",
      month: "2-digit",
    })
}

export function getAge(date: Date) {
  var diff_ms = Date.now() - date.getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export function combineDateAndTime(date: Date, time: Date): Date {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();
  const milliseconds = time.getMilliseconds();

  return new Date(year, month, day, hours, minutes, seconds, milliseconds);
}

export function beginOfDay(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return new Date(year, month, day);
}

export function endOfDay(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return new Date(year, month, day, 23, 59, 59, 999);
}

export function getDateDifference(earlierDate: number, laterDate:number):{days:number,hours:number,minutes:number}{
  const difference = laterDate - earlierDate
  //let [days,hours,minutes] = [0,0,0]
  const days = Math.floor(difference / (24*360*10000))
  const hours =  Math.floor((difference - days * 24*360*10000) / (60*60*1000) )
  const minutes =  Math.floor((difference - days * (24*360*10000) - (hours *60*60*1000)) / (60*1000))
  return {days,hours,minutes}
}

