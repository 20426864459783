"use client";
import { Team } from "@/sources/kicker-amateur/types";
import { useParams } from "next/navigation";
import {useTeam} from "@/app/pwa/lib/pwa-context";
import { getTeamTypeName } from "@/helpers/teamHelpers";

export type HomeTeamProps = {
  team?: Team;
  teamId?: number;
  teamTypeName?: boolean;
  shortName?: boolean;
}

export const useHomeTeamName = ({
  team,
  teamId: forcedTeamId,
  teamTypeName,
  shortName
}: HomeTeamProps): string => {
  const { teamId: searchParamsTeamId } = useParams();
  const teamWithTeamType = useTeam(team?.Id!)

  const teamId = forcedTeamId ?? searchParamsTeamId;

  if (!team) {
    return "Unknown team";
  }

  if (`${teamId}` === `${team.Id}` || teamTypeName) {
    const teamName = getTeamTypeName(teamWithTeamType||team);

    if (teamName) {
      return teamName;
    }
  }

  if (shortName) {
    return team?.ShortName!;
  }

  return team?.OfficalName!;
};
