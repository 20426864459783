import FunctionImage from "@/app/pwa/components/FunctionImage"
import { pwaTeamPlayerPath } from "@/utils/urlHelper"
import Link from "next/link"
import { PlayerCardSmallProps } from "./types"

function PlayerCardSmallImageWrapper({ player,
  teamId,
  widget, children }: PlayerCardSmallProps) {

  return widget ? <div className="w-16 flex-shrink-0">
    {children}
  </div> : <Link
    href={pwaTeamPlayerPath(teamId.toString(), player.Id.toString())}
    className="w-16 flex-shrink-0"
  // className="w-[66px] h-[80px] rounded-md overflow-hidden relative flex-shrink-0"
  >{children}</Link>
}
export function PlayerCardSmallImage({ player,
  ...rest }: PlayerCardSmallProps) {
  return <PlayerCardSmallImageWrapper player={player} {...rest}>
    <FunctionImage className="w-full" imageClassName="@md:rounded-l-lg w-full" useAspectsImageFromSrc={player.LatestFunctionImageUrl} player={player} functionId={player.FunctionId} size="lg" noCache={true} />     
  </PlayerCardSmallImageWrapper>
}
