import Button from "@/app/pwa/components/Button";
import { Can } from "@/app/pwa/lib/ability-context";
import { subject } from "@casl/ability";
import { useSession } from "next-auth/react";
import { PlayerCardSmallProps } from "./types";
import IconSend from "@/assets/icon/send.svg";


export function PlayerCardSmallActions({ 
    player,
    showInvite = true,
    onInvite,
    teamId,
}: PlayerCardSmallProps) {
    const { data: session } = useSession();

    return showInvite && session ? (
        <Can
            do="create"
            on={subject("Invitation", {
                personId: `${player?.Id}`,
                contextId: `${teamId}`,
                contextType: "Team" as "Team",
            })}
        >
            <Button
                color="link-secondary"
                inline={true}
                className="mt-1 max-w-full"
                onClick={() => onInvite && onInvite(player)}
            >
                <span className="text-ellipsis overflow-hidden mr-blank">
                    {player.FirstName}
                </span>
                einladen
                <IconSend className="flex-shrink-0 ml-blank" />
            </Button>
        </Can>
    ) : null
}