import { QuillRichTextEditor } from "@/app/pwa/components/QuillRichTextEditor";

export default function News() {
  return <>
  <style>{` .ql-editor.ql-editor { min-height:8rem;}`}</style>
  <div
  className="overflow-y-hidden h-full w-full bg-gray-100"
  id="container"
>
  {/* <div className="px-2 mt-6 hidden lg:block max-w-basic-page">
    <div className="text-center gap-2 flex  items-center text-gray-200">
      <a
        className="button button--link-muted inline font-compressed whitespace-nowrap text-ellipsis overflow-hidden"
        href="/news"
      >
        Vereinsnews
      </a>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="w-3 inline"
      >
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth={2}
          d="M16.5 12 8 3.5m0 17 8.5-8.5"
        />
      </svg>
      <span className="text-gray-200 font-compressed whitespace-nowrap text-ellipsis overflow-hidden">
        Hinzufügen
      </span>
    </div>
  </div> */}
  <header
    className="bg-gray-50 pl-max-5-or-safe-area-inset-left pr-max-5-or-safe-area-inset-right pt-[calc(0.75rem_+_env(safe-area-inset-top))] pb-3 shadow-header 
  sticky top-0 z-50
  max-w-basic-page "
  >
    <div className="flex justify-center items-center">
      {/* <div className="flex-1">
        <button type="button" className="mobile-nav-button  -ml-4">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth={2}
              d="M7.5 12 16 3.5m0 17L7.5 12"
            />
          </svg>
        </button>
      </div> */}
      <h2 className="uppercase font-compressed font-bold text-xl text-center">
        Neue Vereinsnews
      </h2>
      {/* <div className="flex-1" /> */}
    </div>
  </header>
  <div className="px-2 mt-2 max-w-basic-page">
    <form className="space-y-4" data-text-editor="bound">
      <div className="bg-surface text-gray-900 rounded-md @container/card relative z-10 ">
        <div className="p-4">
          <fieldset className="fieldset flex flex-col ">
            <input
              className="block w-full px-3 py-2.5 border border-gray-900 bg-transparent rounded focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary placeholder:text-gray-200 peer form-field mt-1 "
              id="title"
              type="text"
              name="title"
              value="A-Platz gesperrt"
              readOnly
            />
            <label
              htmlFor="title"
              className="font-bold font-compressed peer-focus:text-secondary order-first block "
            >
              Überschrift
            </label>
          </fieldset>
          <div className="mt-4">
            <label className="font-bold font-compressed peer-focus:text-secondary order-first block">
              Nachricht
            </label>
            <QuillRichTextEditor onChange={() => {}} defaultValue={"Der A-Platz ist aufgrund der schlechten Witterungsbedingungen bis auf Weiteres gesperrt! &#10060;<br />Ascheplatz ist offen."}/>
            {/* <div className="quill mt-1">
              <div className="ql-toolbar ql-snow">
                <span className="ql-formats">
                  <span className="ql-header ql-picker">
                    <span
                      className="ql-picker-label"
                      tabIndex={0}
                      role="button"
                      aria-expanded="false"
                      aria-controls="ql-picker-options-0"
                    >
                      <svg viewBox="0 0 18 18">
                        {" "}
                        <polygon
                          className="ql-stroke"
                          points="7 11 9 13 11 11 7 11"
                        />{" "}
                        <polygon
                          className="ql-stroke"
                          points="7 7 9 5 11 7 7 7"
                        />{" "}
                      </svg>
                    </span>
                    <span
                      className="ql-picker-options"
                      aria-hidden="true"
                      tabIndex={-1}
                      id="ql-picker-options-0"
                    >
                      <span
                        tabIndex={0}
                        role="button"
                        className="ql-picker-item"
                        data-value={1}
                      />
                      <span
                        tabIndex={0}
                        role="button"
                        className="ql-picker-item"
                        data-value={2}
                      />
                      <span
                        tabIndex={0}
                        role="button"
                        className="ql-picker-item ql-selected"
                      />
                    </span>
                  </span>
                  <select className="ql-header" style={{ display: "none" }}>
                    <option value={1} />
                    <option value={2} />
                    <option selected="selected" />
                  </select>
                </span>
                <span className="ql-formats">
                  <button type="button" className="ql-bold">
                    <svg viewBox="0 0 18 18">
                      {" "}
                      <path
                        className="ql-stroke"
                        d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
                      />{" "}
                      <path
                        className="ql-stroke"
                        d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
                      />{" "}
                    </svg>
                  </button>
                  <button type="button" className="ql-italic">
                    <svg viewBox="0 0 18 18">
                      {" "}
                      <line
                        className="ql-stroke"
                        x1={7}
                        x2={13}
                        y1={4}
                        y2={4}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={5}
                        x2={11}
                        y1={14}
                        y2={14}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={8}
                        x2={10}
                        y1={14}
                        y2={4}
                      />{" "}
                    </svg>
                  </button>
                  <button type="button" className="ql-underline">
                    <svg viewBox="0 0 18 18">
                      {" "}
                      <path
                        className="ql-stroke"
                        d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"
                      />{" "}
                      <rect
                        className="ql-fill"
                        height={1}
                        rx="0.5"
                        ry="0.5"
                        width={12}
                        x={3}
                        y={15}
                      />{" "}
                    </svg>
                  </button>
                  <button type="button" className="ql-strike">
                    <svg viewBox="0 0 18 18">
                      {" "}
                      <line
                        className="ql-stroke ql-thin"
                        x1="15.5"
                        x2="2.5"
                        y1="8.5"
                        y2="9.5"
                      />{" "}
                      <path
                        className="ql-fill"
                        d="M9.007,8C6.542,7.791,6,7.519,6,6.5,6,5.792,7.283,5,9,5c1.571,0,2.765.679,2.969,1.309a1,1,0,0,0,1.9-.617C13.356,4.106,11.354,3,9,3,6.2,3,4,4.538,4,6.5a3.2,3.2,0,0,0,.5,1.843Z"
                      />{" "}
                      <path
                        className="ql-fill"
                        d="M8.984,10C11.457,10.208,12,10.479,12,11.5c0,0.708-1.283,1.5-3,1.5-1.571,0-2.765-.679-2.969-1.309a1,1,0,1,0-1.9.617C4.644,13.894,6.646,15,9,15c2.8,0,5-1.538,5-3.5a3.2,3.2,0,0,0-.5-1.843Z"
                      />{" "}
                    </svg>
                  </button>
                </span>
                <span className="ql-formats">
                  <button type="button" className="ql-list" value="ordered">
                    <svg viewBox="0 0 18 18">
                      {" "}
                      <line
                        className="ql-stroke"
                        x1={7}
                        x2={15}
                        y1={4}
                        y2={4}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={7}
                        x2={15}
                        y1={9}
                        y2={9}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={7}
                        x2={15}
                        y1={14}
                        y2={14}
                      />{" "}
                      <line
                        className="ql-stroke ql-thin"
                        x1="2.5"
                        x2="4.5"
                        y1="5.5"
                        y2="5.5"
                      />{" "}
                      <path
                        className="ql-fill"
                        d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"
                      />{" "}
                      <path
                        className="ql-stroke ql-thin"
                        d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"
                      />{" "}
                      <path
                        className="ql-stroke ql-thin"
                        d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"
                      />{" "}
                    </svg>
                  </button>
                  <button type="button" className="ql-list" value="bullet">
                    <svg viewBox="0 0 18 18">
                      {" "}
                      <line
                        className="ql-stroke"
                        x1={6}
                        x2={15}
                        y1={4}
                        y2={4}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={6}
                        x2={15}
                        y1={9}
                        y2={9}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={6}
                        x2={15}
                        y1={14}
                        y2={14}
                      />{" "}
                      <line className="ql-stroke" x1={3} x2={3} y1={4} y2={4} />{" "}
                      <line className="ql-stroke" x1={3} x2={3} y1={9} y2={9} />{" "}
                      <line
                        className="ql-stroke"
                        x1={3}
                        x2={3}
                        y1={14}
                        y2={14}
                      />{" "}
                    </svg>
                  </button>
                </span>
                <span className="ql-formats">
                  <button type="button" className="ql-link">
                    <svg viewBox="0 0 18 18">
                      {" "}
                      <line
                        className="ql-stroke"
                        x1={7}
                        x2={11}
                        y1={7}
                        y2={11}
                      />{" "}
                      <path
                        className="ql-even ql-stroke"
                        d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"
                      />{" "}
                      <path
                        className="ql-even ql-stroke"
                        d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"
                      />{" "}
                    </svg>
                  </button>
                </span>
                <span className="ql-formats">
                  <button type="button" className="ql-clean">
                    <svg className="" viewBox="0 0 18 18">
                      {" "}
                      <line
                        className="ql-stroke"
                        x1={5}
                        x2={13}
                        y1={3}
                        y2={3}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={6}
                        x2="9.35"
                        y1={12}
                        y2={3}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={11}
                        x2={15}
                        y1={11}
                        y2={15}
                      />{" "}
                      <line
                        className="ql-stroke"
                        x1={15}
                        x2={11}
                        y1={11}
                        y2={15}
                      />{" "}
                      <rect
                        className="ql-fill"
                        height={1}
                        rx="0.5"
                        ry="0.5"
                        width={7}
                        x={2}
                        y={14}
                      />{" "}
                    </svg>
                  </button>
                </span>
              </div>
              <div className="ql-container ql-snow">
                <div
                  className="ql-editor"
                  data-gramm="false"
                  contentEditable="true"
                >
                  <p>
                    Der A-Platz ist aufgrund der schlechten
                    Witterungsbedingungen bis auf Weiters gesperrt!{" "}
                  </p>
                </div>
                <div
                  className="ql-clipboard"
                  contentEditable="true"
                  tabIndex={-1}
                />
                <div className="ql-tooltip ql-hidden">
                  <a
                    className="ql-preview"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="about:blank"
                  />
                  <input
                    type="text"
                    data-formula="e=mc^2"
                    data-link="https://quilljs.com"
                    data-video="Embed URL"
                  />
                  <a className="ql-action" />
                  <a className="ql-remove" />
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="mt-4">
            <b>Sichtbarkeit:</b> News können von jedem Besucher gelesen werden.
            Achte deshalb darauf, keine internen Informationen zu
            veröffentlichen.
          </div> */}
          <div className="mt-4">
  <fieldset className="fieldset flex flex-col flex-1">
    <div
      className="peer r-select css-b62m3t-container"
      id="notifications_input"
    >
      <span
        id="react-select-notifications_input-live-region"
        className="css-7pg0cj-a11yText"
      />
      <span
        aria-live="polite"
        aria-atomic="false"
        aria-relevant="additions text"
        role="log"
        className="css-7pg0cj-a11yText"
      />
      <div className="flex peer form-field justify-between items-center w-full px-3 py-1.5 border border-gray-900 bg-transparent rounded placeholder:text-gray-200 r-select__control css-ecmtp8-control">
        <div className="r-select__value-container r-select__value-container--has-value css-1m3xje4">
          <div className="r-select__single-value css-1dimb5e-singleValue">
            <div className="flex gap-1.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="w-4 h-4 inline-block shrink-0 relative top-1"
              >
                <g
                  fill="none"
                  fillRule="evenodd"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path d="M12 4.5a8.47 8.47 0 0 1 8.5 8.5c0 2.3-.91 4.39-2.4 5.91l-1.08.95a8.5 8.5 0 0 1-10.04 0l-1.08-.95A8.48 8.48 0 0 1 12 4.5Z" />
                  <path
                    strokeLinecap="round"
                    d="M12 13v6m0-6-4.14 2.24M6.4 2.4a12.07 12.07 0 0 0-4.77 4.56M17.6 2.4a12.07 12.07 0 0 1 4.77 4.56"
                  />
                </g>
              </svg>
              <div>
                <div className="">Sofort Benachrichtigen</div>
              </div>
            </div>
          </div>
          {/* <input
            id="react-select-notifications_input-input"
            tabIndex={0}
            inputMode="none"
            aria-autocomplete="list"
            aria-expanded="false"
            aria-haspopup="true"
            role="combobox"
            aria-readonly="true"
            className="css-1hac4vs-dummyInput"
            defaultValue=""
          /> */}
        </div>
        <div className="text-gray-900 r-select__indicators css-1wy0on6">
          <div
            className="r-select__indicator r-select__dropdown-indicator css-h7zr3y-indicatorContainer"
            aria-hidden="true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="h-4 w-4"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth={2}
                d="M12 17 3.5 8.5m17 0L12 17"
              />
            </svg>
          </div>
        </div>
      </div>
      <input type="hidden" defaultValue="default" name="notifications" />
    </div>
  </fieldset>
  <button
    className="
inline-flex
button
button--primary

w-full justify-center mt-2"
    type="submit"
  >
    {" "}
    News erstellen
  </button>
</div>

        </div>
      </div>
    </form>
  </div>
</div>

  </>
}
