
import { useAbility } from "@/app/pwa/lib/ability-context";
import { subject } from "@casl/ability";
import React from "react";
import { PlayerCardSmallProps } from "./types";
import { PlayerCardSmallData } from "./PlayerCardSmallData";
import { PlayerCardSmallImage } from "./PlayerCardSmallImage";
import { PlayerCardSmallActions } from "./PlayerCardActions";

interface PlayerCardSmallWrapperProps {
  isInFuture?: boolean | string
  children: React.ReactNode
}

function PlayerCardSmallWrapper({ isInFuture, children }: PlayerCardSmallWrapperProps) {
  return <div className={`flex space-x-3 min-w-0 ${isInFuture ? "opacity-50 hover:opacity-100" : ""}`}>
    {children}
  </div>
}

export function PlayerCardSmall({
  ...rest
}: PlayerCardSmallProps) {
  const {
    player,
    teamId,
  } = rest

  const ability = useAbility();
  const canManage = ability.can("manage", subject("Team", { id: teamId.toString() }));

  const isInFuture =
    player.InTeamSince && new Date(player.InTeamSince) > new Date();

  if (isInFuture && !canManage) {
    return null;
  }

  return (
    <PlayerCardSmallWrapper isInFuture={isInFuture}>
      <PlayerCardSmallImage {...rest} />
      <div className="flex-1 min-w-0">
        <PlayerCardSmallData {...rest} />
        <PlayerCardSmallActions {...rest} />
      </div>
    </PlayerCardSmallWrapper>
  );
}



export function PlayerCardSmallStatic({
  ...rest
}: PlayerCardSmallProps) {
  const {
    player,
    teamId,
  } = rest

  return (
    <PlayerCardSmallWrapper isInFuture={false}>
      <PlayerCardSmallImage {...rest} />
      <div className="flex-1 min-w-0">
        <PlayerCardSmallData {...rest} />
      </div>
    </PlayerCardSmallWrapper>
  );
}
