import Checkmark from "@/assets/icon/checkmark.svg"

export default function CheckList({className, children} : {className?: string, children?: React.ReactNode}) {
  return <ul className={`${className || ""} flex flex-col gap-0.5`}>
    {children}
  </ul>
}

export function CheckListItem({children} : {children?: React.ReactNode}) {
  return <li className="flex gap-1.5">
    <Checkmark className="w-4 h-4 text-green mt-[0.2em] shrink-0" />
    <div>
      {children}
    </div>
  </li>
}
