"use client"
import Slider from "react-slick";
import "@/css/_react-slick.css";
import Button from "@/app/pwa/components/Button";
import ChevronRight from "@/assets/icon/chevron-right.svg";
import Close from "@/assets/icon/close.svg";


import { Club, SquadPositionPlayer } from "@/sources/kicker-amateur/types";
import { useCallback, useRef, useEffect, useState } from "react";
import SlideWelcome from "./Slides/SlideWelcome";
import SlidePlayers from "./Slides/SlidePlayers";
import SlideTrainer from "./Slides/SlideTrainer";
// import SlidePR from "./Slides/SlidePR";
import SlideParents from "./Slides/SlideParents";
import SlideFans from "./Slides/SlideFans";
import SlideSoul from "./Slides/SlideSoul";
import SlideUpdate from "./Slides/SlideUpdate";
import SlideLetsGo from "./Slides/SlideLetsGo";

export function OnboardingSlide({imageContent, textContent}:{imageContent:React.ReactNode, textContent:React.ReactNode}) {
  return <div className="flex flex-col h-full">
  <div className="flex items-center justify-center flex-grow flex-1 min-h-0 relative">
    {imageContent}
  </div>
  <div className="bg-gray-50 pt-6 px-4 pb-[7.5rem] onboarding-slider__text-content">
    {textContent}
  </div>
</div>
}

export function OnboardingTag({children}: {children?:React.ReactNode}) {
  return <div className="flex justify-center -mt-8 z-50 relative">
    <div className="bg-highlight mx-auto px-2 py-1 font-bold text-lg text-black-forced">
      {children}
    </div>
  </div>
}

export function OnboardingScreenshot({children, className, screenshotClassName} : {children?:React.ReactNode, className?:string, screenshotClassName?:string}) {
  // TODO:Timo add pointer-events-none
  return <div className={`pointer-events-none absolute left-0 right-0 bottom-0 top-0 overflow-hidden ${className}`}>
    <div className={`absolute bottom-0 rounded-t-2xl bg-gray-50 p-2  overflow-hidden shadow-lg origin-bottom ${screenshotClassName} onboarding-screenshot`}>
      {children}
    </div>
  </div>
}

// type SliderInstance = Slider & {
//   slickGoTo: (slide: number) => void;
//   slickNext: () => void;
// };

export function OnboardingFeature({children, className} : {children?:React.ReactNode, className?:string}) {
  // TODO:Timo add pointer-events-none
  return <div className={`pointer-events-none absolute rounded-lg bg-gray-50 p-2 overflow-hidden shadow-lg ${className}`}>
    {children}
  </div>
}

export default function OnboardingSlider({clubContext, pwa, player, teamId, contestId, homeUrl, onClose} : {clubContext?: boolean, pwa: Club, player: SquadPositionPlayer, teamId: number, contestId: number, homeUrl: string, onClose?: Function}) {
  let sliderRef = useRef<Slider | null>(null)

  const skip = useCallback(() => {
    if (sliderRef.current) {
      (sliderRef.current as unknown as Slider).slickGoTo(10);
    }
  }, []);

  const next = useCallback(() => {
    if (sliderRef.current) {
      (sliderRef.current as unknown as Slider).slickNext();
    }
  }, []);

  useEffect(() => {
    // Declare interval variable at the top of the useEffect
    let interval: any = undefined;

    const closeUsercentricsModal = () => {
      if (window?.UC_UI && window?.UC_UI?.closeCMP) {
        window.UC_UI.closeCMP()
      } else {
        console.log('Usercentrics API is not available yet.');
      }
    };

    // Check if Usercentrics is ready, then close the modal
    if (window?.UC_UI) {
      closeUsercentricsModal();
    } else {
      // Wait for Usercentrics to be ready
      interval = setInterval(() => {
        if (window.UC_UI) {
          clearInterval(interval);
          closeUsercentricsModal();
        }
      }, 100); // Check every 100ms
    }

    return () => {
      // Cleanup interval if the component unmounts before Usercentrics is ready
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  return <div className="onboarding-slider relative">
    <div className="onboarding-slider-container">

        <Slider
        // initialSlide={7}
        slidesToShow={1}
        slidesToScroll={1}
        dots
        infinite={false}
        speed={500}
        arrows={false}
        ref={sliderRef}
        className="slick-slider--on-light"
        beforeChange={(oldIndex, newIndex) => {
          // console.log("beforeChange")
          // console.log("oldIndex: ", oldIndex)
          // console.log("newIndex: ", newIndex)
          const buttons = document.getElementById("onboarding-buttons")
          const slider = document.getElementsByClassName("slick-slider")[0]
          if(newIndex === 6) {
            buttons?.classList.add("opacity-0", "pointer-events-none")
            slider?.classList.remove("slick-slider--on-light")
          } else {
            buttons?.classList.remove("opacity-0", "pointer-events-none")
            slider?.classList.add("slick-slider--on-light")
          }
        }}
        >
          <SlideWelcome pwa={pwa} clubContext={clubContext}/>
          
          <SlidePlayers player={player} teamId={teamId} contestId={contestId} />
          
          <SlideTrainer />

          {/* <SlidePR pwa={pwa} /> */}
          
          <SlideSoul />

          <SlideParents />
          
          <SlideFans />

          {clubContext 
            ?
              <SlideUpdate pwa={pwa} homeUrl={homeUrl} />
            :
              <SlideLetsGo pwa={pwa} homeUrl={homeUrl} onButtonClick={onClose} />
          }

      </Slider>

      {clubContext !== true &&
        <div className="absolute top-0 right-0">
          <Button color="link-muted" className="text-white-forced drop-shadow" onClick={() => {onClose && onClose()}}><Close /></Button>
        </div>
      }

      <div id="onboarding-buttons" className="absolute bottom-0 left-0 right-0 flex justify-between p-6 pt-0 duration-fast">
        <Button color="link-muted" className="-ml-4" onClick={skip}>Überspringen</Button>
        <Button color="primary" onClick={next}>
          Weiter <ChevronRight className="ml-blank" />
        </Button>
      </div>
    </div>
  </div>
}
