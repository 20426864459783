const nationsmap: Record<string, string> = {
  ENG: "GB_ENG",
  KOS: "XK",
  ANT: "AN",
  NIR: "GB_NIR",
  SCT: "GB_SCT",
  WLS: "GB_WLS",
};

export function getFlagCode(code: string) {
  return nationsmap[code] ?? code;
}
