import { getDateWithDay, getTime } from "@/helpers/dateHelper"

function Event({title, date}:{title:string, date:number}) {
  return <>
    <div>
      <div className="bg-surface text-gray-900 rounded-md @container/card p-1 relative  ">
        <div>
          <div className="px-3 pt-3">
            <div className="flex items-baseline gap-1 mb-2">
              <a
                className="bg-gray-900 text-gray-50 rounded px-1.5 font-bold uppercase pb-px text-sm"
                href="/team/39762201/termine"
              >
                C-Junioren (U15) II
              </a>
            </div>
          </div>
          <a
            className="flex flex-col justify-center items-start px-3 pb-3 duration-fast hover:bg-gray-100/50 active:bg-gray-100"
            href="/termin/66227732ca65184309ddb7ee"
          >
            <h3 className="text-lg font-semibold ">{title}</h3>
            <div className="flex items-center justify-center gap-2">
              <span className="">{getDateWithDay(date / 1000)}</span>
              <span className="w-0.5 bg-gray-150 h-4"></span>
              <span className="">{getTime(date)} Uhr</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </>
}

export default function TrainingCalendar() {
  const now = new Date(); // Aktuelle Zeit
  const tomorrow = new Date(now); // Kopie der aktuellen Zeit
  // Einen Tag hinzufügen
  tomorrow.setDate(now.getDate() + 1);
  // Uhrzeit auf 13:30 setzen
  tomorrow.setHours(13, 30, 0, 0);

  return <>
    <div
  className="overflow-y-hidden h-full bg-gray-100"
  id="container"
>
  {/*$*/}
  {/*$*/}
  {/*/$*/}
  <header
    className="bg-gray-50 pl-max-5-or-safe-area-inset-left pr-max-5-or-safe-area-inset-right pt-[calc(0.75rem_+_env(safe-area-inset-top))] pb-3 shadow-header 
  sticky top-0 z-50
  max-w-basic-page "
  >
    <div className="flex justify-between items-center">
      <div className="flex-1">
        <div className="-ml-3 mr-3">
          <button className="mobile-nav-button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <g fill="currentColor" transform="translate(2 5)">
                <rect width={20} height={2} y={12} rx={1} />
                <rect width={20} height={2} y={6} rx={1} />
                <rect width={20} height={2} rx={1} />
              </g>
            </svg>
          </button>
        </div>
      </div>
      <h2 className="uppercase font-compressed font-bold text-xl text-center">
        Termine
      </h2>
      <div className="flex-1">
        <div className="flex justify-end -mr-3">
          <button
            className="
inline-flex
button
button--link-secondary

"
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
              className="text-lg sm:text-base sm:mr-blank"
            >
              <path
                fill="currentColor"
                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32v144H48c-17.7 0-32 14.3-32 32s14.3 32 32 32h144v144c0 17.7 14.3 32 32 32s32-14.3 32-32V288h144c17.7 0 32-14.3 32-32s-14.3-32-32-32H256z"
              />
            </svg>
            {/* <span className="hidden sm:block">Neuer Termin</span> */}
          </button>
        </div>
      </div>
    </div>
    <div className="flex justify-center">
      <div className="content-switch mt-2">
        <a
          className="content-switch__button content-switch__button--active"
          href="/termine"
        >
          <div className="content-switch__button-content">Aktuelle Termine</div>
        </a>
        <a className="content-switch__button " href="/termine?timeframe=past">
          <div className="content-switch__button-content">
            Vergangene Termine
          </div>
        </a>
      </div>
    </div>
  </header>
  <div className="px-2 mt-3 max-w-basic-page pb-24">
    <div className="flex flex-col gap-space-y">
      


      <Event title="Trainingslager" date={new Date(new Date().setDate(new Date().getDate() + 1)).setHours(13, 30, 0, 0)} />
      <Event title="Konditionstraining" date={new Date(new Date().setDate(new Date().getDate() + 4)).setHours(9, 30, 0, 0)} />
      <Event title="Training" date={new Date(new Date().setDate(new Date().getDate() + 8)).setHours(13, 30, 0, 0)} />
      <Event title="Training" date={new Date(new Date().setDate(new Date().getDate() + 15)).setHours(13, 30, 0, 0)} />
      <Event title="Training" date={new Date(new Date().setDate(new Date().getDate() + 22)).setHours(13, 30, 0, 0)} />
      <Event title="Training" date={new Date(new Date().setDate(new Date().getDate() + 29)).setHours(13, 30, 0, 0)} />
      <Event title="Training" date={new Date(new Date().setDate(new Date().getDate() + 36)).setHours(13, 30, 0, 0)} />
    </div>
    <div className="mt-6 flex justify-center">
      <button
        className="
inline-flex
button
button--secondary

w-full justify-center"
      >
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="mr-blank"
        >
          <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={2}
            d="M12 17 3.5 8.5m17 0L12 17"
          />
        </svg>
        Weitere Termine laden
      </button>
    </div>
  </div>
  <div className="h-32" />
  {/* <footer className="pl-max-5-or-safe-area-inset-left pr-max-5-or-safe-area-inset-right pb-max-3-or-safe-area-inset-bottom fixed z-40 bottom-0 left-0 right-0 bg-surface shadow-[0_-2px_10px_0_rgba(0,0,0,0.08),_0_-1px_3px_0_rgba(0,0,0,0.05)] lg:hidden">
    <div className="flex items-baseline justify-around @container">
      <a
        className="content-tab-bar__link opacity-50 flex items-center flex-col flex-1 pt-1"
        href="/"
      >
        <div className="content-tab-bar__icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M10 2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zM9 4H4v5h5zm12-2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm-1 2h-5v5h5zm1 9a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1zm-1 2h-5v5h5zm-10-2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1zm-1 2H4v5h5z"
            />
          </svg>
        </div>
        <div className="text-xs xs:text-sm font-compressed uppercase">
          Dashboard
        </div>
      </a>
      <a
        className="content-tab-bar__link  flex items-center flex-col flex-1 pt-1"
        href="/termine"
      >
        <div className="content-tab-bar__icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path
                fill="currentColor"
                fillRule="nonzero"
                d="M19.5 3A2.5 2.5 0 0 1 22 5.5v15a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 2 20.5v-15A2.5 2.5 0 0 1 4.5 3zm0 2h-15a.5.5 0 0 0-.5.5v15c0 .28.22.5.5.5h15a.5.5 0 0 0 .5-.5v-15a.5.5 0 0 0-.5-.5"
              />
              <path
                fill="var(--team)"
                d="M4.5 3h15A2.5 2.5 0 0 1 22 5.5V12H2V5.5A2.5 2.5 0 0 1 4.5 3"
              />
              <path
                fill="currentColor"
                fillRule="nonzero"
                d="M16 2v4a1 1 0 0 0 2 0V2a1 1 0 0 0-2 0M6 2v4a1 1 0 1 0 2 0V2a1 1 0 1 0-2 0"
              />
            </g>
          </svg>
        </div>
        <div className="text-xs xs:text-sm font-compressed uppercase">
          Termine
        </div>
      </a>
      <a
        className="content-tab-bar__link opacity-50 flex items-center flex-col flex-1 pt-1"
        href="/umfragen"
      >
        <div className="content-tab-bar__icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M20 11H4v2h16zm2 3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1zm-5 8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1zM12 6a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1zm3 15v-2H4v2zM10 5V3H4v2z"
            />
          </svg>
        </div>
        <div className="text-xs xs:text-sm font-compressed uppercase">
          Abstimmungen
        </div>
      </a>
      <a
        className="content-tab-bar__link opacity-50 flex items-center flex-col flex-1 pt-1"
        href="/benachrichtigungen"
      >
        <div className="content-tab-bar__icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g fill="currentColor">
              <path d="M12 3.5a9.5 9.5 0 0 1 6.79 16.14l-1.15 1a9.5 9.5 0 0 1-11.28 0l-1.15-1A9.5 9.5 0 0 1 12 3.5m0 2a7.5 7.5 0 0 0-5.57 12.51l.17.18 1 .88.2.15a7.5 7.5 0 0 0 3.88 1.27l.32.01c1.52 0 2.96-.45 4.2-1.28l.2-.15 1-.88.17-.18a7.43 7.43 0 0 0 1.92-4.7l.01-.3A7.5 7.5 0 0 0 12 5.5" />
              <path d="M12 12a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1" />
              <path d="M11.52 12.12a1 1 0 0 1 .96 1.76l-4.15 2.24a1 1 0 0 1-.95-1.76zM5.92 1.5a1 1 0 0 1 .94 1.77 11.07 11.07 0 0 0-4.37 4.2A1 1 0 0 1 .77 6.46a13.07 13.07 0 0 1 5.15-4.95Zm12.16 0a1 1 0 0 0-.94 1.77c1.81.97 3.33 2.42 4.37 4.2a1 1 0 0 0 1.72-1.01 13.07 13.07 0 0 0-5.15-4.95Z" />
            </g>
          </svg>
        </div>
        <div className="text-xs xs:text-sm font-compressed uppercase">
          <span>Benachrichtigungen</span>
        </div>
      </a>
    </div>
  </footer> */}
  {/*/$*/}
</div>

  </>
}
