import { Person, PersonData } from "@/sources/kicker-amateur/types";
import IconDefaultPlayer from "@/assets/icon/default-player.png";
import Image from "./Image";
// TODO:Timo TODO:Matthias use svg

interface FunctionImageType {
  player?: PersonData | Person,
  functionId?: number,
  className?: string,
  imageClassName?: string,
  showNumber?: boolean,
  noCache?:boolean,
  size?: "sm" | "base" | "lg" //controls radius and player number font size, not the actual height/width
  useAspectsImageFromSrc?:string
}


// TODO:Timo why is alt not rendered?

export default function FunctionImage(
  {
    player,
    functionId,
    className,
    imageClassName,
    showNumber = true,
    noCache = false,
    size = "base",
    useAspectsImageFromSrc,
    ...attributes
  }
    :
    FunctionImageType) {
  let labelClassName;

  switch (size) {
    case "sm":
      labelClassName = "text-sm";
      imageClassName += " rounded-md"
      break;

    case "lg":
      labelClassName = "text-lg";
      imageClassName += " rounded-lg"
      break;

    default:
      labelClassName = "";
      imageClassName += " rounded-md"
      break;
  }
  return (
    <div className={`relative aspect-player ${className || ""}`}>
      <Image
        {...attributes}
        className={`object-cover w-full aspect-player h-auto ${imageClassName}`}
        width="66"
        height="80"
        src={useAspectsImageFromSrc ?? (functionId ? `/api/image/function/${functionId}${noCache ? "?no_cache=true" : ""}` : IconDefaultPlayer.src)}
        alt={`${player?.FirstName} ${player?.LastName}`}
        fallback={IconDefaultPlayer.src}
      />
      { player && showNumber && "ShirtNumber" in player  && player.ShirtNumber &&
        <div className={`absolute bottom-1 left-1 rounded-[0.25em] px-[0.25em] bg-team text-team-contrast font-semibold ${labelClassName}`}>
          {player.ShirtNumber}
        </div>
      }
    </div>
  )
}
